import React, { useEffect, useLayoutEffect, useState, memo, useCallback } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, TextArea, A, Img } from "../components"
import styled, { keyframes } from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import { useBean } from "../context/BeanContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import PopupNotification from "../components/PopupNotification"
import debounce from "lodash.debounce"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function phoneFormat(input) {
    //returns (###) ###-####
    input = input.replace(/\D/g, "")
    var size = input.length
    if (size > 0) {
        input = "(" + input
    }
    if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
    }
    if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
    }
    return input
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2).match(/\d/g).join("")
    } else {
        usersNumber = number
    }
    return usersNumber
}

function validateNumber(number) {
    const cleanedNumber = cleanNumber(number)
    const match = cleanedNumber.match(/\d/g)
    if (match && match.length === 10) {
        return match.join("")
    } else if (match && match.length === 11 && match[0] === "1") {
        return match.slice(1).join("")
    } else {
        return false
    }
}

function BeanPage() {
    const { authenticatedUser } = useAuth()
    const {
        loading,
        usersBean,
        friendsBeans,
        usersRealBean,
        usersCurrentBean,
        nextBeanDate,
        setUsersBean,
        setUsersRealBean,
        setFriendsBeans,
        pastBeans,
    } = useBean()
    const [openedItem, setOpenedItem] = useState("")
    const [openedContainer, setOpenedContainer] = useState("")
    const [updateBeanScreen, setUpdateBeanScreen] = useState(false)
    const [createBeanScreen, setCreateBeanScreen] = useState(false)
    const [beanShareNumber, setBeanShareNumber] = useState("")
    const [beanShareNumberError, setBeanShareNumberError] = useState("")
    const [updateOrCreateBeanError, setUpdateOrCreateBeanError] = useState(false)
    const [beanShareLoading, setBeanShareLoading] = useState(false)
    const [beanSharedList, setBeanSharedList] = useState([])
    const [beanStart, setBeanStart] = useState(false)
    const [beanCallLoading, setBeanCallLoading] = useState(false)
    const [popupNotifText, setPopupNotifText] = useState("")
    const [popupNotifColor, setPopupNotifColor] = useState("")
    const [cheeseShooterArr, setCheeseShooterArr] = useState([])
    const [beansCheeseCount, setBeansCheeseCount] = useState({})
    const [usersBeanTitle, setUsersBeanTitle] = useState(usersRealBean?.beanTitle)
    const [beanHasChanged, setBeanHasChanged] = useState(false)

    async function addOrUpdateBean(e) {
        e.stopPropagation()
        setBeanCallLoading(true)
        if (!usersBean.bean) {
            setUpdateOrCreateBeanError("Nothing entered")
        }
        try {
            const { data } = await axios.post(`/updateOrCreateBean`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    usersBean: usersBean.bean,
                    usersBeanTitle,
                },
            })
            if (data.error) {
                console.log(data.error, "ERROR")

                setBeanCallLoading(false)
                setUpdateOrCreateBeanError("Failed to update or create your bean :(")
                return false
            } else {
                setBeanCallLoading(false)
                setOpenedItem(false)
                if (updateBeanScreen) setUpdateBeanScreen(false)
                if (createBeanScreen) setCreateBeanScreen(false)
                setUsersBean({ ...usersBean, bean: data.newBean, beanTitle: data.newBeanTitle })
                setUsersRealBean({ ...usersRealBean, bean: data.newBean, beanTitle: data.newBeanTitle })
                return true
            }
        } catch (error) {
            console.log(error, "ERROR")

            setBeanCallLoading(false)
            setUpdateOrCreateBeanError("Failed to update or create your bean :(")
            return false
        }
    }

    //     updateOrCreateBean()
    // }

    async function sendBeanShare(e) {
        e.stopPropagation()
        setBeanShareLoading(true)
        const friendsNumber = validateNumber(beanShareNumber)
        if (!friendsNumber) {
            setBeanShareNumberError("Not a good number")
            setBeanShareLoading(false)
        } else {
            return shareBeanWithFriend()
        }
    }

    async function shareBeanWithFriend() {
        try {
            const { data } = await axios.post(`/shareBeanText`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    beanShareNumber: validateNumber(beanShareNumber),
                },
            })
            if (data.error) {
                setBeanShareLoading(false)
                setBeanShareNumberError("failed")
                setBeanSharedList([
                    ...beanSharedList,
                    {
                        number: formatNumber(validateNumber(beanShareNumber)),
                        message: data.error,
                    },
                ])
                return false
            } else {
                setBeanShareLoading(false)
                setBeanSharedList([
                    ...beanSharedList,
                    {
                        number: formatNumber(validateNumber(beanShareNumber)),
                        message: "sent!",
                    },
                ])
                setBeanShareNumber("")
                return true
                // setOpenedItem(false)
                // setSentFriendRequests(data.friendRequestsSent)
            }
        } catch (error) {
            setBeanShareLoading(false)
            setBeanShareNumberError("Failed to add friend")
            console.log(error, "ERROR")
            return false
        }
    }

    const debouncedCheeseCountCall = useCallback(debounce(increaseCheeseCountCall, 1000), [])

    async function increaseCheeseCountCall(beanId, cheeseCount, friendsNumber) {
        try {
            const APIURL = "/beanCheese"
            const { data } = await axios.post(APIURL, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    beanId,
                    cheeseCount,
                },
            })
            setFriendsBeans({
                ...friendsBeans,
                [friendsNumber]: {
                    cheeseCount: data.cheeseCount,
                    bean: friendsBeans[friendsNumber].bean,
                    name: friendsBeans[friendsNumber].name,
                    id: friendsBeans[friendsNumber].id,
                },
            })
        } catch (error) {}
    }

    function handleCheeseAdd(friendsNumber, cheeseCount, beanId) {
        setBeansCheeseCount({
            [friendsNumber]: cheeseCount,
        })
        debouncedCheeseCountCall(beanId, cheeseCount, friendsNumber)
        return
    }

    return loading ? (
        <BeanSpinnerDontAsk />
    ) : (
        <Flex flexDirection="column" padding="10px 0" width="100%">
            <ItemExpand
                bg={colors.cheesyBeanYellow}
                opened={true}
                onClick={(e) => {
                    e.stopPropagation()
                    if (!beanStart) {
                        setBeanStart(true)
                    } else if (beanStart || updateBeanScreen) {
                        setBeanStart(false)
                        setUpdateBeanScreen(false)
                    }

                    if (!updateBeanScreen) {
                        setUpdateBeanScreen(true)
                    }
                }}
                open={updateBeanScreen || createBeanScreen ? "480px" : beanStart ? "440px" : "200px"}
                border={
                    updateBeanScreen || createBeanScreen
                        ? buttonDecoration.mediumBorder
                        : beanStart
                        ? buttonDecoration.mediumBorder
                        : ""
                }
                bs={
                    updateBeanScreen || createBeanScreen
                        ? buttonDecoration.mediumBoxshadow
                        : beanStart
                        ? buttonDecoration.mediumBoxshadow
                        : ""
                }
            >
                <Text m="0 0 6px 0" fontWeight="600">
                    {usersRealBean ? "My current Bean" : "Create my Bean"}
                </Text>
                <StyledTextArea
                    id="bean-text-area"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                        setUsersBean({ ...usersBean, bean: e.target.value })
                        if (!updateBeanScreen) setUpdateBeanScreen(true)
                        if (!beanHasChanged) setBeanHasChanged(true)
                    }}
                    padding="6px"
                    value={usersBean?.bean ? usersBean.bean : ""}
                    fontSize="14px"
                    backgroundColor={
                        updateBeanScreen || createBeanScreen || beanStart
                            ? colors.white
                            : colors.veryVeryVeryLightGray
                    }
                    border={"1px solid #000"}
                    borderRadius={buttonDecoration.lightBorderRadius}
                    disabled={!usersRealBean || updateBeanScreen || beanStart ? false : true}
                    onFocus={() => setBeanStart(true)}
                    color={
                        (!updateBeanScreen && !createBeanScreen && !beanStart) || !usersBean.bean
                            ? colors.lightGray
                            : colors.black
                    }
                    height={updateBeanScreen || createBeanScreen || beanStart ? "340px" : "100px"}
                    placeholder={
                        updateOrCreateBeanError
                            ? "Start your CheesyBean here! :)"
                            : "Big life event? \n\nHappy moment? \n\nWork drama?"
                    }
                    opacity={!updateBeanScreen && !createBeanScreen && !beanStart ? 0.85 : 1}
                />
                {(beanStart || updateBeanScreen) && (
                    <>
                        <Text fontWeight="600" m="6px 0">
                            Title{" "}
                            <Span fontWeight="400" fontSize="14px">
                                (optional)
                            </Span>
                        </Text>
                        <Input
                            onClick={(e) => e.stopPropagation()}
                            placeholder="This Bean is cheesy!"
                            padding="6px"
                            value={usersBeanTitle ? usersBeanTitle : ""}
                            onChange={(e) => {
                                setUsersBeanTitle(e.target.value)
                                if (!updateBeanScreen) setUpdateBeanScreen(true)
                                if (!beanHasChanged) setBeanHasChanged(true)
                            }}
                            maxLength="30"
                            fontSize="14px"
                            border={"1px solid #000"}
                            borderRadius={buttonDecoration.lightBorderRadius}
                        ></Input>
                    </>
                )}
                {!usersRealBean && (
                    <Flex>
                        {/* {!beanStart && ( */}
                        <Text fontSize="12px" color={colors.medGray} padding="8px 8px 8px 0" m="0">
                            Your new Bean will become active on Sunday {nextBeanDate}
                        </Text>
                        {/* )} */}
                        <DeleteButton
                            onClick={async (e) => {
                                e.stopPropagation()
                                const resp = await addOrUpdateBean(e)
                                if (resp) {
                                    makePopupNotif(
                                        setPopupNotifColor,
                                        setPopupNotifText,
                                        colors.offerGreen,
                                        "Posted!"
                                    )
                                    if (beanStart) {
                                        setBeanStart(false)
                                    }
                                    if (createBeanScreen) {
                                        setCreateBeanScreen(false)
                                    }
                                } else {
                                    makePopupNotif(
                                        setPopupNotifColor,
                                        setPopupNotifText,
                                        colors.offerGreen,
                                        "Failed to post :("
                                    )
                                }
                            }}
                            fw="600"
                            fs="16px"
                            mt="6px"
                            bg={colors.offOrange}
                            p="6px 14px"
                            disabled={usersBean && !beanCallLoading ? false : true}
                        >
                            <Text m="0">Post</Text>
                        </DeleteButton>
                    </Flex>
                )}
                {usersRealBean && !updateBeanScreen && (
                    <Flex justifyContent="center" alignItems="center" flexDirection="column">
                        <Text
                            fontSize="14px"
                            color={colors.medGray}
                            padding="8px 8px 8px 0"
                            m="0"
                            textAlign="center"
                        >
                            Your new Bean will become active on Sunday {nextBeanDate}
                        </Text>
                        <DeleteButton
                            onClick={(e) => {
                                e.stopPropagation()
                                if (!updateBeanScreen) {
                                    setUpdateBeanScreen(true)
                                }
                            }}
                            fw="600"
                            fs="18px"
                            m="6px 12px 4px 12px"
                            bg={colors.offOrange}
                            p="6px 14px"
                            // disabled={usersBean ? false : true}
                        >
                            <Text m="0">Update your bean</Text>
                        </DeleteButton>
                    </Flex>
                )}
                {usersRealBean && updateBeanScreen && (
                    <Flex justifyContent="space-around" mt="8px">
                        <DeleteButton
                            onClick={async (e) => {
                                e.stopPropagation()
                                if (!updateBeanScreen) {
                                    setUpdateBeanScreen(true)
                                } else {
                                    const resp = await addOrUpdateBean(e)
                                    if (resp) {
                                        makePopupNotif(
                                            setPopupNotifColor,
                                            setPopupNotifText,
                                            colors.offerGreen,
                                            "Updated!"
                                        )
                                        setUpdateBeanScreen(false)
                                        setBeanStart(false)
                                        setBeanHasChanged(false)
                                    } else {
                                        makePopupNotif(
                                            setPopupNotifColor,
                                            setPopupNotifText,
                                            colors.offerGreen,
                                            "Failed to update :("
                                        )
                                    }
                                }
                            }}
                            fw="600"
                            fs="18px"
                            mt="12px"
                            bg={colors.offerGreen}
                            p="6px 14px"
                            disabled={beanCallLoading || !beanHasChanged}
                        >
                            <Text m="0">Confirm</Text>
                        </DeleteButton>
                        <DeleteButton
                            onClick={(e) => {
                                e.stopPropagation()
                                if (updateBeanScreen) {
                                    setUpdateBeanScreen(false)
                                }
                                setUsersBean({
                                    ...usersRealBean.bean,
                                    bean: usersRealBean.bean,
                                    beanTitle: usersRealBean.beanTitle,
                                })
                                setUsersBeanTitle(usersRealBean.beanTitle)
                                setBeanStart(false)
                                setBeanHasChanged(false)
                                if (createBeanScreen) setCreateBeanScreen(false)
                            }}
                            fw="600"
                            fs="18px"
                            mt="12px"
                            bg={colors.offRed}
                            p="6px 14px"
                        >
                            <Text m="0">Cancel</Text>
                        </DeleteButton>
                    </Flex>
                )}
            </ItemExpand>
            <ContainerButton
                onClick={() => {
                    if (openedContainer !== "yourActiveBean") {
                        setOpenedContainer("yourActiveBean")
                        setOpenedItem("")
                        if (beanStart) setBeanStart(false)
                        if (updateBeanScreen) setUpdateBeanScreen(false)
                    } else {
                        setOpenedContainer("")
                        setOpenedItem("")
                        if (beanStart) setBeanStart(false)
                        if (updateBeanScreen) setUpdateBeanScreen(false)
                    }
                }}
                openContainer={openedContainer === "yourActiveBean"}
                className="no-select"
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    My active Bean
                </Text>
                <Img
                    src="circleArrow.svg"
                    width="20px"
                    height="20px"
                    className={`open-arrow${
                        openedContainer === "yourActiveBean" ? " rotate-one-eighty" : ""
                    }`}
                />
            </ContainerButton>
            {openedContainer === "yourActiveBean" && (
                <Box
                    style={{
                        overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {!usersCurrentBean?.bean && (
                            <Flex
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                padding="14px 12px 2px 12px"
                            >
                                <Img src="frownFace.svg" height="30px" width="30px" />
                                <Text textAlign="center" fontWeight="600" m="8px 0 0 0">
                                    No active bean
                                </Text>
                            </Flex>
                        )}
                        {usersCurrentBean?.bean && (
                            <>
                                <ItemExpand
                                    bg={colors.offGreen}
                                    opened={"sendSharedBean" === openedItem}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (openedItem !== "sendSharedBean") {
                                            setOpenedItem("sendSharedBean")
                                        } else {
                                            setOpenedItem("")
                                        }
                                    }}
                                    open={"sendSharedBean" === openedItem}
                                    openHeight={
                                        openedItem === "sendSharedBean"
                                            ? `${beanSharedList.length * 55 + 184}px`
                                            : "140px"
                                    }
                                    closeHeight={"64px"}
                                    bs={"sendSharedBean" === openedItem && buttonDecoration.mediumBoxshadow}
                                    border={"sendSharedBean" === openedItem && buttonDecoration.mediumBorder}
                                    hover={true}
                                    m=""
                                    overflow={true}
                                >
                                    <Flex justifyContent="space-between">
                                        {"sendSharedBean" !== openedItem ? (
                                            <>
                                                <Text
                                                    m="0"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                    alignSelf="center"
                                                    color={colors.white}
                                                >
                                                    Share your Bean with a non-user!
                                                </Text>
                                                <Img src="bluePaperPlane.svg" />
                                            </>
                                        ) : (
                                            <Flex
                                                flexDirection="column"
                                                className="slide-in-from-above"
                                                width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Text
                                                    m="8px 0 0 0"
                                                    fontWeight="600"
                                                    alignSelf="center"
                                                    color={colors.white}
                                                    fontSize="15px"
                                                >
                                                    Share your Bean with a non-user!
                                                </Text>
                                                <Text
                                                    m="0 0 12px 0"
                                                    fontWeight="400"
                                                    alignSelf="center"
                                                    fontSize="12px"
                                                    color={colors.veryVeryLightGray}
                                                >
                                                    Only the recipient can view your Bean
                                                </Text>
                                                <Input
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => {
                                                        setBeanShareNumber(cleanNumber(e.target.value))
                                                    }}
                                                    value={phoneFormat(beanShareNumber)}
                                                    padding="6px"
                                                    fontSize="20px"
                                                    maxLength="18"
                                                    placeholder="(123) 456-7890"
                                                    border={buttonDecoration.lightBorder}
                                                    borderRadius="4px"
                                                    boxShadow={buttonDecoration.veryLightBoxshadow}
                                                ></Input>
                                                <DeleteButton
                                                    disabled={
                                                        beanShareLoading ||
                                                        !beanShareNumber ||
                                                        beanShareNumber.length !== 14
                                                    }
                                                    onClick={async (e) => {
                                                        const resp = await sendBeanShare(e)
                                                        if (resp) {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Sent!"
                                                            )
                                                        } else {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Failed to send"
                                                            )
                                                        }
                                                    }}
                                                    color={colors.lightGreen}
                                                    m="12px 0"
                                                    fs="18px"
                                                    bg={colors.offOrange}
                                                    fontWeight="500"
                                                    color="white"
                                                >
                                                    <Text m="0">
                                                        {beanShareLoading ? "Sending..." : "Send link!"}
                                                    </Text>
                                                </DeleteButton>
                                                {beanSharedList.map((item, index) => (
                                                    <BeanSharedNumber key={index} bg={colors.offBlue}>
                                                        {item.number} {item.message}
                                                    </BeanSharedNumber>
                                                ))}
                                            </Flex>
                                        )}
                                    </Flex>
                                </ItemExpand>
                                <ItemExpand
                                    hover={true}
                                    open={authenticatedUser.number === openedItem}
                                    openHeight={"456px"}
                                    closeHeight={"40px"}
                                    key={authenticatedUser.number}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (openedItem !== authenticatedUser.number) {
                                            setOpenedItem(authenticatedUser.number)
                                        } else {
                                            setOpenedItem("")
                                        }
                                    }}
                                    bg={usersCurrentBean.bean ? colors.cheesyBeanYellow : colors.lightGray}
                                    bs={
                                        authenticatedUser.number === openedItem &&
                                        buttonDecoration.mediumBoxshadow
                                    }
                                    border={
                                        authenticatedUser.number === openedItem &&
                                        buttonDecoration.mediumBorder
                                    }
                                    disabled={usersCurrentBean.bean}
                                    overflow
                                >
                                    <BeanButtonContainer
                                        justifyContent="space-between"
                                        alignItems="center"
                                        paddingBottom={authenticatedUser.number !== openedItem ? "0" : "6px"}
                                    >
                                        <Text
                                            margin="0"
                                            alignSelf={
                                                authenticatedUser.number === openedItem ? "end" : "center"
                                            }
                                        >
                                            {authenticatedUser.name}
                                        </Text>
                                        <Flex paddingRight="0" alignItems="end">
                                            <Text m="0" p="0 0 0 0">
                                                {usersCurrentBean.cheeseCount
                                                    ? usersCurrentBean.cheeseCount
                                                    : 0}{" "}
                                            </Text>
                                            <Img
                                                marginLeft="6px"
                                                height="30px"
                                                width="30px"
                                                src="cheeseIcon.png"
                                            />
                                            <Text m="0" p="0 0 0 0">
                                                's
                                            </Text>
                                        </Flex>
                                    </BeanButtonContainer>
                                    {authenticatedUser.number === openedItem && usersCurrentBean.bean && (
                                        <StyledTextArea
                                            padding="6px"
                                            height="400px"
                                            color={colors.black}
                                            fontSize="14px"
                                            borderRadius="4px"
                                            backgroundColor={colors.veryVeryVeryLightGray}
                                            border={"1px solid #000"}
                                            defaultValue={usersCurrentBean.bean}
                                            onClick={(e) => e.stopPropagation()}
                                            readOnly={true}
                                            onBlur={() => {
                                                setBeanStart(false)
                                                // zoom out for the user
                                                document.body.style.zoom = 1.0
                                            }}
                                        />
                                    )}
                                    {authenticatedUser.number === openedItem && !usersCurrentBean.bean && (
                                        <Text textAlign="center" className="fade-in">
                                            No bean! :(
                                        </Text>
                                    )}
                                </ItemExpand>
                            </>
                        )}
                    </ItemContainer>
                </Box>
            )}
            <ContainerButton
                onClick={() => {
                    if (friendsBeans && Object.keys(friendsBeans)?.length) {
                        if (openedContainer !== "friendsActiveBeans") {
                            setOpenedContainer("friendsActiveBeans")
                            setOpenedItem("")
                            if (beanStart) setBeanStart(false)
                            if (updateBeanScreen) setUpdateBeanScreen(false)
                        } else {
                            setOpenedContainer("")
                            setOpenedItem("")
                            if (beanStart) setBeanStart(false)
                            if (updateBeanScreen) setUpdateBeanScreen(false)
                        }
                    }
                }}
                openContainer={openedContainer === "friendsActiveBeans"}
                className="no-select"
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    Friend's active Beans
                    <Span fontSize="14px" fontWeight="400">
                        (
                        {(friendsBeans &&
                            Object.keys(friendsBeans).filter(
                                (friendsNumber) => friendsBeans[friendsNumber].bean
                            ).length) ||
                            0}
                        )
                    </Span>
                </Text>
                {friendsBeans && Object.keys(friendsBeans).length && (
                    <Img
                        src="circleArrow.svg"
                        width="20px"
                        height="20px"
                        className={`open-arrow${
                            openedContainer === "friendsActiveBeans" ? " rotate-one-eighty" : ""
                        }`}
                    />
                )}
            </ContainerButton>
            {openedContainer === "friendsActiveBeans" && (
                <Box
                    className="temp-overflow-hidden"
                    style={{
                        // overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {friendsBeans &&
                            Object.keys(friendsBeans)
                                .filter((friendsNumber) => friendsBeans[friendsNumber].bean)
                                .map((friendsNumber) => (
                                    <ItemExpand
                                        openHeight={"460px"}
                                        closeHeight={friendsBeans[friendsNumber].beanTitle ? "54px" : "40px"}
                                        hover={true}
                                        open={friendsNumber === openedItem}
                                        key={friendsNumber}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setCheeseShooterArr([])
                                            if (openedItem !== friendsNumber) {
                                                // e.target.style.overflow = "hidden"
                                                setOpenedItem(friendsNumber)
                                                // setTimeout(() => {
                                                //     e.target.style.overflow = ""
                                                // }, 500)
                                            } else {
                                                setOpenedItem("")
                                            }
                                        }}
                                        bg={
                                            friendsBeans[friendsNumber].bean
                                                ? colors.cheesyBeanYellow
                                                : colors.lightGray
                                        }
                                        bs={friendsNumber === openedItem && buttonDecoration.mediumBoxshadow}
                                        border={friendsNumber === openedItem && buttonDecoration.mediumBorder}
                                        disabled={friendsBeans[friendsNumber].bean}
                                    >
                                        <Flex overflow="hidden" width="100%" flexDirection="column">
                                            <Flex justifyContent="space-between">
                                                {friendsBeans[friendsNumber].beanTitle ? (
                                                    <Flex flexDirection="column" mb="6px">
                                                        <Text
                                                            margin={
                                                                friendsNumber === openedItem
                                                                    ? "0 0 6px 0"
                                                                    : "0"
                                                            }
                                                        >
                                                            {friendsBeans[friendsNumber].beanTitle}
                                                        </Text>
                                                        <Span fontSize="12px" color={colors.medGray}>
                                                            {friendsBeans[friendsNumber].name}
                                                        </Span>
                                                    </Flex>
                                                ) : (
                                                    <Text
                                                        margin={
                                                            friendsNumber === openedItem ? "0 0 6px 0" : "0"
                                                        }
                                                    >
                                                        {friendsBeans[friendsNumber].name}
                                                    </Text>
                                                )}
                                            </Flex>
                                            {friendsNumber === openedItem &&
                                                friendsBeans[friendsNumber].bean && (
                                                    <StyledTextArea
                                                        height="400px"
                                                        color={colors.black}
                                                        fontSize="14px"
                                                        backgroundColor={colors.veryVeryVeryLightGray}
                                                        padding="6px"
                                                        borderRadius="4px"
                                                        onClick={(e) => e.stopPropagation()}
                                                        readOnly={true}
                                                        border={"1px solid #000"}
                                                        defaultValue={friendsBeans[friendsNumber].bean}
                                                        onBlur={() => {
                                                            setBeanStart(false)
                                                            // zoom out for the user
                                                            document.body.style.zoom = 1.0
                                                        }}
                                                    />
                                                )}
                                        </Flex>
                                        {friendsNumber === openedItem && friendsBeans[friendsNumber].bean && (
                                            <Flex justifyContent="space-between" mt="12px">
                                                <Flex
                                                    className="cheese-button"
                                                    position="relative"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        const timeInMiliseconds = Date.now()
                                                        const animationTime =
                                                            Math.floor(Math.random() * 3000) + 4000
                                                        const x =
                                                            (Math.random() < 0.5 ? -1 : 1) *
                                                            (Math.floor(Math.random() * 1800) + 1000)
                                                        const y =
                                                            (Math.random() < 0.5 ? -1 : 1) *
                                                            (Math.floor(Math.random() * 1800) + 1000)
                                                        setCheeseShooterArr((prevValue) => [
                                                            ...prevValue,
                                                            {
                                                                id: timeInMiliseconds,
                                                                time: animationTime / 1000,
                                                                x,
                                                                y,
                                                            },
                                                        ])
                                                        if (!beansCheeseCount[friendsNumber]) {
                                                            handleCheeseAdd(
                                                                friendsNumber,
                                                                friendsBeans[friendsNumber].cheeseCount + 1,
                                                                friendsBeans[friendsNumber].id
                                                            )
                                                        } else {
                                                            handleCheeseAdd(
                                                                friendsNumber,
                                                                beansCheeseCount[friendsNumber] + 1,
                                                                friendsBeans[friendsNumber].id
                                                            )
                                                        }

                                                        setTimeout(() => {
                                                            setCheeseShooterArr((prevValue) =>
                                                                prevValue.filter(
                                                                    (_) => _.id !== timeInMiliseconds
                                                                )
                                                            )
                                                        }, animationTime)
                                                    }}
                                                >
                                                    <BeanButtonContainer
                                                        alignItems="end"
                                                        paddingBottom="6px"
                                                        position="relative"
                                                    >
                                                        <Text m="0">give</Text>
                                                        <Flex position="relative">
                                                            <Img
                                                                marginLeft="6px"
                                                                height="36px"
                                                                width="36px"
                                                                src="cheeseIcon.png"
                                                                marginRight="3px"
                                                            />
                                                            {cheeseShooterArr?.map((_) => {
                                                                return (
                                                                    <CheeseShooterComponent
                                                                        key={`${_.id}-cheeseShooter`}
                                                                        time={_.time}
                                                                        x={_.x}
                                                                        y={_.y}
                                                                    />
                                                                )
                                                            })}
                                                        </Flex>
                                                        <Text m="0" p="0 12px 0 0">
                                                            !
                                                        </Text>
                                                    </BeanButtonContainer>
                                                </Flex>
                                                <Flex>
                                                    <DeleteButton
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            Object.assign(document.createElement("a"), {
                                                                href: `sms:+1${friendsNumber}`,
                                                            }).click()
                                                        }}
                                                        fw="600"
                                                        fs="20px"
                                                        m="0 12px 6px 0px"
                                                        bg={colors.offerBlue}
                                                        p="6px 18px"
                                                    >
                                                        Text
                                                    </DeleteButton>
                                                    <DeleteButton
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            Object.assign(document.createElement("a"), {
                                                                href: `tel:+1${friendsNumber}`,
                                                            }).click()
                                                        }}
                                                        fw="600"
                                                        fs="20px"
                                                        m="0 0 6px 0"
                                                        bg={colors.offerGreen}
                                                        p="6px 18px"
                                                    >
                                                        Call
                                                    </DeleteButton>
                                                </Flex>
                                            </Flex>
                                        )}
                                        {friendsNumber === openedItem &&
                                            !friendsBeans[friendsNumber].bean && (
                                                <Text textAlign="center" className="fade-in">
                                                    No bean! :(
                                                </Text>
                                            )}
                                    </ItemExpand>
                                ))}
                        {friendsBeans &&
                            Object.keys(friendsBeans).filter(
                                (friendsNumber) => !friendsBeans[friendsNumber].bean
                            ).length > 0 && (
                                <ContainerButton
                                    onClick={() => {
                                        if (openedItem !== "noBeanFriends") {
                                            setOpenedItem("noBeanFriends")
                                        } else {
                                            setOpenedItem("")
                                        }
                                    }}
                                    openContainer={openedItem === "noBeanFriends"}
                                    className="no-select"
                                    mt="12px"
                                    bg={colors.lightGray}
                                >
                                    <Text m="0" color={colors.wordsChosen} fontSize="14px">
                                        Friend's with no bean
                                    </Text>
                                    <Img
                                        src="circleArrow.svg"
                                        width="20px"
                                        height="20px"
                                        className={`open-arrow${
                                            openedItem === "noBeanFriends" ? " rotate-one-eighty" : ""
                                        }`}
                                    />
                                </ContainerButton>
                            )}
                        <Flex flexDirection="column" p="0 2px 0 10px" overflow="hidden">
                            {openedItem === "noBeanFriends" &&
                                friendsBeans &&
                                Object.keys(friendsBeans)
                                    .filter((friendsNumber) => !friendsBeans[friendsNumber].bean)
                                    .map((friendsNumber) => (
                                        <ItemContainer
                                            open={true}
                                            className="slide-in-from-above"
                                            key={friendsNumber}
                                        >
                                            <ItemExpand
                                                open={
                                                    friendsNumber === openedItem
                                                        ? friendsBeans[friendsNumber].bean
                                                            ? "460px"
                                                            : "100px"
                                                        : "48px"
                                                }
                                                hover={true}
                                                key={friendsNumber}
                                                bg={
                                                    friendsBeans[friendsNumber].bean
                                                        ? colors.cheesyBeanYellow
                                                        : colors.lightGray
                                                }
                                                disabled={friendsBeans[friendsNumber].bean}
                                                hover={false}
                                            >
                                                <Flex justifyContent="space-between">
                                                    <Text margin="0 6px 0 0">
                                                        {friendsBeans[friendsNumber].name}
                                                    </Text>
                                                </Flex>
                                            </ItemExpand>
                                        </ItemContainer>
                                    ))}
                            {/* </Flex> */}
                        </Flex>
                    </ItemContainer>
                </Box>
            )}
            <ContainerButton
                onClick={() => {
                    if (pastBeans?.length) {
                        if (openedContainer !== "myPastBeans") {
                            setOpenedContainer("myPastBeans")
                            setOpenedItem("")
                            if (beanStart) setBeanStart(false)
                            if (updateBeanScreen) setUpdateBeanScreen(false)
                        } else {
                            setOpenedContainer("")
                            setOpenedItem("")
                            if (beanStart) setBeanStart(false)
                            if (updateBeanScreen) setUpdateBeanScreen(false)
                        }
                    }
                }}
                openContainer={openedContainer === "myPastBeans"}
                className="no-select"
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    My past Beans
                    <Span fontSize="14px" fontWeight="400">
                        ({pastBeans?.length || 0})
                    </Span>
                </Text>
                {pastBeans && Object.keys(pastBeans).length && (
                    <Img
                        src="circleArrow.svg"
                        width="20px"
                        height="20px"
                        className={`open-arrow${
                            openedContainer === "myPastBeans" ? " rotate-one-eighty" : ""
                        }`}
                    />
                )}
            </ContainerButton>
            {openedContainer === "myPastBeans" && (
                <Box
                    className="temp-overflow-hidden"
                    style={{
                        // overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {pastBeans.map(({ bean, creation_date }, index) => (
                            <ItemExpand
                                openHeight={"440px"}
                                closeHeight={"40px"}
                                hover={true}
                                open={creation_date === openedItem}
                                key={creation_date}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCheeseShooterArr([])
                                    if (openedItem !== creation_date) {
                                        setOpenedItem(creation_date)
                                    } else {
                                        setOpenedItem("")
                                    }
                                }}
                                bg={colors.cheesyBeanYellow}
                                bs={creation_date === openedItem && buttonDecoration.mediumBoxshadow}
                                border={creation_date === openedItem && buttonDecoration.mediumBorder}
                            >
                                <Flex justifyContent="space-between">
                                    <Text margin={creation_date === openedItem ? "0 0 6px 0" : "0"}>
                                        {new Date(Number(creation_date)).toLocaleString("en-us", {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        })}
                                    </Text>
                                </Flex>
                                {creation_date === openedItem && (
                                    <StyledTextArea
                                        height="400px"
                                        color={colors.black}
                                        fontSize="14px"
                                        backgroundColor={colors.veryVeryVeryLightGray}
                                        padding="6px"
                                        borderRadius="4px"
                                        onClick={(e) => e.stopPropagation()}
                                        readOnly={true}
                                        border={"1px solid #000"}
                                        defaultValue={bean}
                                        onBlur={() => {
                                            setBeanStart(false)
                                            // zoom out for the user
                                            document.body.style.zoom = 1.0
                                        }}
                                    />
                                )}
                            </ItemExpand>
                        ))}
                    </ItemContainer>
                </Box>
            )}
            <Flex height="80px"></Flex>
            <PopupNotification bg={popupNotifColor} text={popupNotifText} />
        </Flex>
    )
}

export default BeanPage

const BeanButtonContainer = styled(Flex)`
    user-select: none;
`

// scale(1)
// scale(1.3)
// scale(2)
const shootOutRandom = (x, y) => keyframes`
0% {
    transform: translate(0, 0);
}

50% {
    transform: translate(${x / 2}px, ${y / 2}px) rotate(980deg);
}

100% {
    transform: translate(${x}px, ${y}px) rotate(1720deg);
}
`

const CheeseShooter = styled(Img)`
    animation: ${({ x, y }) => shootOutRandom(x, y)} ${({ time }) => time}s ease-in;
    position: absolute;
    height: 32px;
    width: 32px;
`

// const tempOverflowHidden = keyframes`
// 0% {
//     overflow: hidden;
// }

// 100% {
//     overflow: hidden;
// }
// `
// .temp-overflow-hidden {
//     animation: tempOverflowHidden 0.8s ease;
// }

const CheeseShooterComponent = memo(({ index, time, x, y }) => {
    // Make this component only rerender if a prop changes
    return (
        <CheeseShooter
            src="cheeseIcon.png"
            className="flying-cheese"
            key={index}
            index={index}
            time={time}
            x={x}
            y={y}
        />
    )
})
//     animation: ${tempOverflowHidden} 5s linear;
const ItemExpand = styled(Flex)`
    border-radius: 8px;
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    padding: ${({ p }) => (p ? p : "8px 10px")};
    margin: ${({ m }) => (m ? m : "8px 0")};
    flex-direction: column;
    user-select: none;
    justify-content: center;
    transition: height 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}
    height: ${({ open, openHeight, closeHeight }) => (open ? openHeight : closeHeight)};
    ${({ overflow }) => (overflow ? `overflow: hidden;` : "")}
    ${({ hover }) =>
        hover
            ? `&:hover {
        cursor: pointer;
    }`
            : ""}
`

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById("popup-notif").style.display = "flex"
    setTimeout(() => {
        let notif = document.getElementById("popup-notif")
        notif.style.display = "none"
    }, 4000)
}

const StyledLink = styled(A)`
    text-decoration: none;
    color: ${colors.wordsNotChosen};
    padding: 8px 10px;
    background-color: ${colors.veryVeryLightGray};
    display: flex;
    width: max-content;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    margin-top: 8px;
    ${({ mr }) => (mr ? `margin-right: ${mr};` : "")}
`

const StyledTextArea = styled(TextArea)`
    transition: all 0.3s ease;
    resize: none;
    -webkit-text-fill-color: ${({ color }) =>
        color ? color : colors.black}; /* Override iOS / Android font color change */
    -webkit-opacity: ${({ opacity }) => (opacity ? opacity : 1)};
    ::placeholder {
        color: ${colors.white};
    }
`

const BeanSharedNumber = styled(Flex)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryVeryLightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    background-color: ${({ bg }) => (bg ? bg : colors.buttonBackground)};
    padding: ${({ p }) => (p ? p : "8px 10px")};
    margin: ${({ m }) => (m ? m : "8px 0")};
    border-radius: 6px;
    font-size: 14px;
    flex-direction: column;
    transition: all 0.3s ease;
`

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    background-color: ${({ bg }) => (bg ? bg : colors.buttonBackground)};
    padding: ${({ p }) => (p ? p : "8px 10px")};
    margin: ${({ m }) => (m ? m : "8px 0")};
    border-radius: 6px;
    color: ${({ color }) => (color ? color : "white")};
    ${({ fw }) => (fw ? `font-weight: ${fw};` : "")}
    ${({ fs }) => (fs ? `font-size: ${fs};` : "")}
    ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}
    height: fit-content;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`

const ContainerButton = styled(Flex)`
    align-items: center;
    background-color: ${({ bg }) => (bg ? bg : "white")};
    border-radius: 8px;
    padding: 4px 10px;
    justify-content: space-between;
    margin-top: ${({ mt }) => (mt ? mt : "20px")};
    transition: all 0.3s ease;
    box-shadow: ${({ openContainer }) =>
        openContainer ? buttonDecoration.lightBoxshadow : buttonDecoration.veryLightBoxshadow};
    border: ${({ openContainer }) =>
        openContainer ? buttonDecoration.mediumBorder : buttonDecoration.lightBorder};

    &:hover {
        cursor: pointer;
    }
`

const ItemContainer = styled(Flex)`
    flex-direction: column;
    transition: all 0.3s ease;
`

const IconImg = styled(Img)`
    height: 30px;
    width: 30px;
    ${({ disabled }) => (disabled ? `opacity: 0.5; pointer-events: none;` : "")};

    &:hover {
        cursor: pointer;
    }
`

const ContainerHeading = styled(Flex)`
    align-items: center;
    background-color: ${colors.offBlue};
    border-radius: 6px;
    padding: 4px 10px;
    justify-content: space-between;
    margin-top: 20px;
    transition: all 0.3s ease;
    width: fit-content;
    border: ${({ openContainer }) =>
        openContainer ? buttonDecoration.mediumBorder : buttonDecoration.mediumBorder};
`
