import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import axios from "../axios"
import { useAuth } from "./AuthContext"
import { useFriend } from "./FriendContext"

const BeanContext = createContext()

export default function BeanProvider({ children }) {
    const [friendsBeans, setFriendsBeans] = useState("")
    const [usersBean, setUsersBean] = useState("")
    const [usersRealBean, setUsersRealBean] = useState("")
    const [usersCurrentBean, setUsersCurrentBean] = useState("")
    const [nextBeanDate, setNextBeanDate] = useState("")
    const [pastBeans, setPastBeans] = useState([])
    const [loading, setLoading] = useState(false)
    const { authenticatedUser } = useAuth()
    const { friends } = useFriend()

    useEffect(() => {
        async function getBeans() {
            if (authenticatedUser.number) {
                try {
                    const { data } = await axios.get(`/bean?user=${authenticatedUser.number}`, {
                        withCredentials: true,
                    })
                    setUsersBean(data.newBean)
                    setUsersRealBean(data.newBean)
                    setFriendsBeans(data.friendsBeans)
                    setUsersCurrentBean(data.currentBean)
                    setPastBeans(data.pastBeans)
                    const nextBeanDateConverted = new Date(Number(data.nextBeanDate)).toLocaleString()
                    setNextBeanDate(nextBeanDateConverted)
                    setLoading(false)
                } catch (error) {
                    console.log(error, "ERROR")
                    setLoading(false)
                }
            }
        }

        getBeans()
        // When they login we get the beans, if they accept a friend we get the beans again to get the new ones
    }, [authenticatedUser, friends])

    const memoedValue = useMemo(
        () => ({
            loading,
            friendsBeans,
            usersBean,
            usersRealBean,
            usersCurrentBean,
            nextBeanDate,
            pastBeans,
            setUsersBean,
            setUsersRealBean,
            setFriendsBeans,
        }),
        [
            loading,
            friendsBeans,
            usersBean,
            usersRealBean,
            usersCurrentBean,
            nextBeanDate,
            setFriendsBeans,
            pastBeans,
        ]
    )

    return <BeanContext.Provider value={memoedValue}>{children}</BeanContext.Provider>
}

export function useBean() {
    return useContext(BeanContext)
}
