import React, { useEffect, useState } from "react"
// import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img, StyledLink } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useFriend } from "../context/FriendContext"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import buttonDecoration from "../buttonDecoration"
import PopupNotification from "../components/PopupNotification"
import { colorStyle } from "styled-system"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function validateNumber(number) {
    const match = number.match(/\d/g)
    if (match && match?.length === 10) {
        return match.join("")
    } else if (match && match?.length === 11) {
        return match.slice(1).join("")
    } else {
        return false
    }
}

function FriendPage() {
    const [openedItem, setOpenedItem] = useState("")
    const [friendAddNumber, setFriendAddNumber] = useState("")
    const [popupNotifText, setPopupNotifText] = useState("")
    const [popupNotifColor, setPopupNotifColor] = useState("")

    const { authenticatedUser } = useAuth()

    console.log(authenticatedUser)

    const {
        deleteFriend,
        addFriend,
        acceptFriendRequest,
        denyFriendRequest,
        deleteFriendRequest,
        friends,
        receivedFriendRequests,
        sentFriendRequests,
        friendContextLoading,
        addFriendError,
        deleteFriendError,
        getFriendsError,
        acceptFriendRequestError,
        denyFriendRequestError,
        deleteFriendRequestError,
        addFriendLoading,
        setAddFriendError,
        setAddFriendLoading,
        // Friend request reminder logic
        sendFriendRequestReminder,
        friendRequestReminderLoading,
        friendRequestReminderError,
        friendRequestReminderSuccess,
        getFriends,
        deleteFriendRequestLoading,
        deleteFriendLoading,
        acceptFriendRequestLoading,
        denyFriendRequestLoading,
    } = useFriend()

    async function deleteFriendCall(e, friendsNumber) {
        e.stopPropagation()
        return deleteFriend(friendsNumber)
    }

    async function deleteFriendRequestCall(e, friendsNumber) {
        e.stopPropagation()
        return deleteFriendRequest(friendsNumber)
    }

    async function sendFriendRequestReminderCall(e, friendsNumber) {
        e.stopPropagation()
        return sendFriendRequestReminder(friendsNumber)
    }

    async function acceptFriendRequestCall(e, friendsNumber) {
        e.stopPropagation()
        return acceptFriendRequest(friendsNumber)
    }

    async function denyFriendRequestCall(e, friendsNumber) {
        e.stopPropagation()
        return denyFriendRequest(friendsNumber)
    }

    // function addFriendCall(e) {
    //     e.stopPropagation()
    //     setAddFriendLoading(true)
    //     const friendsNumber = validateNumber(friendAddNumber)
    //     if (!friendsNumber) {
    //         setAddFriendError("Not a good number")
    //         setAddFriendLoading(false)
    //     } else {
    //         addFriend(setOpenedItem, friendsNumber)
    //     }
    // }

    return friendContextLoading ? (
        <BeanSpinnerDontAsk />
    ) : (
        <Flex flexDirection="column" padding="10px 0" width="100%">
            <ItemExpand
                bg={colors.offGreen}
                opened={"addFriend" === openedItem}
                onClick={(e) => {
                    e.stopPropagation()
                    if (openedItem !== "addFriend") {
                        setOpenedItem("addFriend")
                    } else {
                        setOpenedItem("")
                    }
                }}
                open={"addFriend" === openedItem}
                openHeight={"154px"}
                closeHeight={"64px"}
                bs={"addFriend" === openedItem && buttonDecoration.heavyBoxshadow}
                border={"addFriend" === openedItem && buttonDecoration.mediumBorder}
                hover={true}
                m=""
            >
                <Flex justifyContent="space-between">
                    {"addFriend" !== openedItem ? (
                        <>
                            <Text m="0" fontWeight="600" alignSelf="center" color={colors.white}>
                                Add your friends!
                            </Text>
                            <Img src="bluePaperPlane.svg" />
                        </>
                    ) : (
                        <Flex
                            flexDirection="column"
                            className="slide-in-from-above"
                            width="100%"
                            justifyContent="center"
                        >
                            <Text m="0" fontWeight="600" alignSelf="center" color={colors.white}>
                                Add friends by...
                            </Text>
                            <Flex justifyContent="space-between" mt="8px" width="100%" alignSelf="center">
                                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                    <IconImg
                                        src="chatBubble.png"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            Object.assign(document.createElement("a"), {
                                                href: `sms://?&body=${`Add me on CheesyBean! It's like BeReal, but way more real 🤫 https://share.cheesy-bean.com/#/profile/${authenticatedUser.id}`}`,
                                            }).click()
                                            makePopupNotif(
                                                setPopupNotifColor,
                                                setPopupNotifText,
                                                colors.offerGreen,
                                                "texts opened!"
                                            )
                                        }}
                                        height="46px"
                                        width="46px"
                                        border={buttonDecoration.mediumBorder}
                                        p="4px"
                                        bg={colors.offerBlue}
                                        boxShadow={buttonDecoration.veryLightBoxshadow}
                                        borderRadius="6px"
                                    />
                                    <Text fontSize="10px" m="2px 0 0 0" textAlign="center" color="white">
                                        (Texting <Span fontWeight="600">your link</Span>)
                                    </Text>
                                </Flex>
                                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                    <IconImg
                                        src="qrCode.png"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            document.getElementById("help-window").style.display = "flex"
                                        }}
                                        height="46px"
                                        width="46px"
                                        border={buttonDecoration.mediumBorder}
                                        p="4px"
                                        bg={colors.white}
                                        boxShadow={buttonDecoration.veryLightBoxshadow}
                                        borderRadius="6px"
                                    />
                                    <Text fontSize="10px" m="2px 0 0 0" textAlign="center" color="white">
                                        (Showing <Span fontWeight="600">your QR</Span>)
                                    </Text>
                                </Flex>
                                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                    <IconImg
                                        src="paperAndPencil.png"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            navigator.clipboard.writeText(
                                                `https://share.cheesy-bean.com/#/profile/${authenticatedUser.id}`
                                            )
                                            makePopupNotif(
                                                setPopupNotifColor,
                                                setPopupNotifText,
                                                colors.offerGreen,
                                                "copied your link!"
                                            )
                                        }}
                                        height="46px"
                                        width="46px"
                                        border={buttonDecoration.mediumBorder}
                                        p="4px"
                                        bg={colors.offerGreen}
                                        boxShadow={buttonDecoration.veryLightBoxshadow}
                                        borderRadius="6px"
                                    />
                                    <Text fontSize="10px" m="2px 0 0 0" textAlign="center" color="white">
                                        (Copying <Span fontWeight="600">your link</Span>)
                                    </Text>
                                </Flex>
                            </Flex>
                            <Text
                                fontSize="13px"
                                textAlign="center"
                                color="white"
                                fontWeight="600"
                                m="10px 0 0 0"
                            >
                                This is your profile link!
                            </Text>
                            {/* <Text
                                m="10px 0 0 0"
                                fontWeight="600"
                                alignSelf="center"
                                color={colors.white}
                            >
                                Share CheesyBean by...
                            </Text>
                            <Flex
                                justifyContent="space-between"
                                mt="8px"
                                width="100%"
                                alignSelf="center"
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <IconImg
                                        src="paperAndPencil.png"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            navigator.clipboard.writeText(
                                                `https://cheesy-bean.com/#/`
                                            )
                                            makePopupNotif(
                                                setPopupNotifColor,
                                                setPopupNotifText,
                                                colors.offerGreen,
                                                "copied CheesyBean link!"
                                            )
                                        }}
                                        height="46px"
                                        width="46px"
                                        border={buttonDecoration.mediumBorder}
                                        p="4px"
                                        bg={colors.offerGreen}
                                        boxShadow={
                                            buttonDecoration.veryLightBoxshadow
                                        }
                                        borderRadius="6px"
                                    />
                                    <Text
                                        fontSize="10px"
                                        m="2px 0 0 0"
                                        textAlign="center"
                                    >
                                        (Copying CheesyBean's link)
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <IconImg
                                        src="chatBubble.png"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            Object.assign(
                                                document.createElement("a"),
                                                {
                                                    href: `sms://?&body=Here's CheesyBean, a fun way to stay in touch with friends! https://cheesy-bean.com/#/`,
                                                }
                                            ).click()
                                            makePopupNotif(
                                                setPopupNotifColor,
                                                setPopupNotifText,
                                                colors.offerGreen,
                                                "texts opened!"
                                            )
                                        }}
                                        height="46px"
                                        width="46px"
                                        border={buttonDecoration.mediumBorder}
                                        p="4px"
                                        bg={colors.offerBlue}
                                        boxShadow={
                                            buttonDecoration.veryLightBoxshadow
                                        }
                                        borderRadius="6px"
                                    />
                                    <Text
                                        fontSize="10px"
                                        m="2px 0 0 0"
                                        textAlign="center"
                                    >
                                        (Texting CheesyBean's link)
                                    </Text>
                                </Flex>
                            </Flex> */}
                        </Flex>
                    )}
                </Flex>
            </ItemExpand>
            <ContainerButton
                onClick={() => {
                    if (sentFriendRequests?.length) {
                        if (openedItem !== "sentFriendRequests") {
                            setOpenedItem("sentFriendRequests")
                        } else {
                            setOpenedItem("")
                        }
                    }
                }}
                openContainer={openedItem === "sentFriendRequests"}
                className="no-select"
                hover={sentFriendRequests?.length}
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    Friend requests sent
                    <Span fontSize="14px" fontWeight="400">
                        ({sentFriendRequests?.length || 0})
                    </Span>
                </Text>
                {sentFriendRequests?.length > 0 && (
                    <Img
                        src="circleArrow.svg"
                        width="20px"
                        height="20px"
                        className={`open-arrow${
                            openedItem === "sentFriendRequests" ? " rotate-one-eighty" : ""
                        }`}
                    />
                )}
            </ContainerButton>
            {openedItem === "sentFriendRequests" && (
                <Box
                    style={{
                        overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {sentFriendRequests?.map((friend) => {
                            return (
                                <ItemExpand
                                    opened={friend.number === openedItem}
                                    key={friend.number}
                                    open={friend.number === openedItem && "100px"}
                                    bg={colors.cheesyBeanYellow}
                                    bs={"none"}
                                    border={buttonDecoration.mediumBorder}
                                    m="6px 0"
                                >
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Flex flexDirection="column">
                                            {friend?.name?.includes("signed up") ? (
                                                <>
                                                    <Text m="0">{formatNumber(friend.number)}</Text>
                                                    <Text
                                                        margin="0 6px 0 0"
                                                        fontSize="10px"
                                                        color={colors.gray}
                                                    >
                                                        {friend.name}
                                                    </Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Text margin="0 6px 0 0">{friend.name}</Text>
                                                    <Text m="0" fontSize="10px" color={colors.gray}>
                                                        {formatNumber(friend.number)}
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>
                                        <Flex alignItems="center">
                                            <Flex flexDirection="column" alignItems="center">
                                                <IconImgContainer
                                                    onClick={async (e) => {
                                                        e.stopPropagation()
                                                        const resp = await sendFriendRequestReminderCall(
                                                            e,
                                                            friend.number
                                                        )
                                                        if (resp) {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Reminder sent!"
                                                            )
                                                        } else {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.errorRed,
                                                                "Failed to send reminder."
                                                            )
                                                        }
                                                    }}
                                                    bg={colors.brightPurple}
                                                    padding="6px"
                                                    height="40px"
                                                    width="40px"
                                                    disabled={
                                                        friendRequestReminderLoading ||
                                                        friendRequestReminderSuccess === friend.number ||
                                                        !friend.canSendReminder
                                                    }
                                                >
                                                    <Img src="whitePointer.svg" height="100%" width="100%" />
                                                </IconImgContainer>
                                                <Text fontSize="8px" color={colors.black} m="0">
                                                    (poke)
                                                </Text>
                                            </Flex>
                                            <Text
                                                fontSize="12px"
                                                color={colors.medGray}
                                                mt="4px"
                                                ml="6px"
                                                mr="4px"
                                            >
                                                or
                                            </Text>
                                            <Flex flexDirection="column" alignItems="center">
                                                <IconImgContainer
                                                    onClick={async (e) => {
                                                        const resp = await deleteFriendRequestCall(
                                                            e,
                                                            friend.number
                                                        )
                                                        if (resp) {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Friend request cancelled."
                                                            )
                                                        } else {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.errorRed,
                                                                "Failed to delete friend request."
                                                            )
                                                        }
                                                    }}
                                                    bg={colors.brightRed}
                                                    height="40px"
                                                    width="40px"
                                                    disabled={deleteFriendRequestLoading}
                                                >
                                                    <Img src="redCross.svg" height="100%" width="100%" />
                                                </IconImgContainer>
                                                <Text fontSize="8px" color={colors.black} m="0">
                                                    (cancel)
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </ItemExpand>
                            )
                        })}
                    </ItemContainer>
                </Box>
            )}
            <ContainerButton
                onClick={() => {
                    if (receivedFriendRequests?.length) {
                        if (openedItem !== "receivedFriendRequests") {
                            setOpenedItem("receivedFriendRequests")
                        } else {
                            setOpenedItem("")
                        }
                    }
                }}
                openContainer={openedItem === "receivedFriendRequests"}
                className="no-select"
                hover={receivedFriendRequests?.length}
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    Friend requests received
                    <Span fontSize="14px" fontWeight="400">
                        ({receivedFriendRequests?.length || 0})
                    </Span>
                </Text>
                {receivedFriendRequests?.length > 0 && (
                    <Img
                        src="circleArrow.svg"
                        width="20px"
                        height="20px"
                        className={`open-arrow${
                            openedItem === "receivedFriendRequests" ? " rotate-one-eighty" : ""
                        }`}
                    />
                )}
            </ContainerButton>
            {openedItem === "receivedFriendRequests" && (
                <Box
                    style={{
                        overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {receivedFriendRequests.map((friend) => {
                            return (
                                <ItemExpand
                                    opened={friend.number === openedItem}
                                    key={friend.number}
                                    open={friend.number === openedItem && "100px"}
                                    bg={colors.cheesyBeanYellow}
                                    bs={"none"}
                                    border={buttonDecoration.mediumBorder}
                                    m="6px 0"
                                >
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Text margin="0 6px 0 0">{friend.name}</Text>
                                        <Flex alignItems="center">
                                            <Flex flexDirection="column" alignItems="center">
                                                <IconImgContainer
                                                    onClick={async (e) => {
                                                        const resp = await acceptFriendRequestCall(
                                                            e,
                                                            friend.number
                                                        )
                                                        if (resp) {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Accepted friend request"
                                                            )
                                                        } else {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.errorRed,
                                                                "Failed to accept friend request"
                                                            )
                                                        }
                                                    }}
                                                    bg={colors.offerGreen}
                                                    padding="6px"
                                                    height="40px"
                                                    width="40px"
                                                    disabled={acceptFriendRequestLoading}
                                                >
                                                    <Img
                                                        src="whiteCheckmark.svg"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </IconImgContainer>
                                                <Text fontSize="8px" color={colors.black} m="0">
                                                    (accept)
                                                </Text>
                                            </Flex>
                                            <Text
                                                fontSize="12px"
                                                color={colors.medGray}
                                                mt="4px"
                                                mr="4px"
                                                ml="6px"
                                            >
                                                or
                                            </Text>
                                            <Flex flexDirection="column" alignItems="center">
                                                <IconImgContainer
                                                    onClick={async (e) => {
                                                        const resp = await denyFriendRequestCall(
                                                            e,
                                                            friend.number
                                                        )
                                                        if (resp) {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.offerGreen,
                                                                "Denied friend request"
                                                            )
                                                        } else {
                                                            makePopupNotif(
                                                                setPopupNotifColor,
                                                                setPopupNotifText,
                                                                colors.errorRed,
                                                                "Failed to deny friend request"
                                                            )
                                                        }
                                                    }}
                                                    bg={colors.offRed}
                                                    height="40px"
                                                    width="40px"
                                                    disabled={denyFriendRequestLoading}
                                                >
                                                    <Img src="redCross.svg" height="100%" width="100%" />
                                                </IconImgContainer>
                                                <Text fontSize="8px" color={colors.black} m="0">
                                                    (deny)
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </ItemExpand>
                            )
                        })}
                    </ItemContainer>
                </Box>
            )}
            <ContainerButton
                onClick={() => {
                    if (friends?.length) {
                        if (openedItem !== "friends") {
                            setOpenedItem("friends")
                        } else {
                            setOpenedItem("")
                        }
                    }
                }}
                openContainer={openedItem === "friends"}
                className="no-select"
                hover={friends?.length}
            >
                <Text m="0" color={colors.wordsChosen} fontSize="18px" fontWeight="500">
                    Friends
                    <Span fontSize="14px" fontWeight="400">
                        ({friends?.length || 0})
                    </Span>
                </Text>
                {friends?.length > 0 && (
                    <Img
                        src="circleArrow.svg"
                        width="20px"
                        height="20px"
                        className={`open-arrow${openedItem === "friends" ? " rotate-one-eighty" : ""}`}
                    />
                )}
            </ContainerButton>
            {openedItem === "friends" && (
                <Box
                    style={{
                        overflow: "hidden",
                        padding: "0 6px",
                        paddingTop: "10px",
                    }}
                >
                    <ItemContainer open={true} className="slide-in-from-above">
                        {friends?.map((friend) => {
                            return (
                                <ItemExpand
                                    opened={friend.friend === openedItem}
                                    key={friend.friend}
                                    open={friend.friend === openedItem && "100px"}
                                    bg={colors.cheesyBeanYellow}
                                    bs={"none"}
                                    border={buttonDecoration.mediumBorder}
                                    m="6px 0"
                                >
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Text margin="0 6px 0 0">{friend.name}</Text>
                                        <Flex flexDirection="column" alignItems="center">
                                            <IconImgContainer
                                                onClick={async (e) => {
                                                    const resp = await deleteFriendCall(e, friend.friend)
                                                    if (resp) {
                                                        makePopupNotif(
                                                            setPopupNotifColor,
                                                            setPopupNotifText,
                                                            colors.offerGreen,
                                                            "Deleted friend"
                                                        )
                                                    } else {
                                                        makePopupNotif(
                                                            setPopupNotifColor,
                                                            setPopupNotifText,
                                                            colors.errorRed,
                                                            "Failed to delete friend"
                                                        )
                                                    }
                                                }}
                                                bg={colors.offRed}
                                                height="40px"
                                                width="40px"
                                                disabled={deleteFriendLoading}
                                            >
                                                <Img src="redCross.svg" height="100%" width="100%" />
                                            </IconImgContainer>
                                            <Text fontSize="8px" color={colors.black} m="0">
                                                (delete)
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </ItemExpand>
                            )
                        })}
                    </ItemContainer>
                </Box>
            )}
            <Flex height="80px"></Flex>
            <PopupNotification bg={popupNotifColor} text={popupNotifText} id={"popup-notif"} />
        </Flex>
    )
}

// THE STATES OF THE FRIEND REQUEST REMINDER BUTTON
// {friendRequestReminderLoading &&
//     friendRequestReminderSuccess !==
//         friend.number &&
//     "Sending.."}
// {!friendRequestReminderLoading &&
//     friendRequestReminderSuccess !==
//         friend.number &&
//     friend.canSendReminder &&
//     "Send reminder"}
// {((friendRequestReminderSuccess ===
//     friend.number &&
//     !friendRequestReminderLoading) ||
//     !friend.canSendReminder) &&
//     "Reminder sent!"}

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById("popup-notif").style.display = "flex"
    setTimeout(() => {
        let notif = document.getElementById("popup-notif")
        notif.style.display = "none"
    }, 4000)
}

export default FriendPage

const ItemExpand = styled(Flex)`
    border-radius: 8px;
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.lightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    ${buttonDecoration.lightBorder};
    padding: ${({ p }) => (p ? p : "8px 10px")};
    margin: ${({ m }) => (m ? m : "8px 0")};
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}
    height: ${({ open, openHeight, closeHeight }) => (open ? openHeight : closeHeight)};
    overflow: hidden;
    ${({ hover }) =>
        hover
            ? `&:hover {
        cursor: pointer;
    }`
            : ""}
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    margin-top: 16px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    ${({ fw }) => (fw ? `font-weight: ${fw};` : "")}
    ${({ fs }) => (fs ? `font-size: ${fs};` : "")}
    ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}
    ${({ bg }) => (bg ? `background-color: ${bg};` : colors.buttonBackground)}
    ${({ display }) => (display ? `display: ${display};` : "")}

    &:hover {
        cursor: pointer;
        ${({ bg }) => (bg ? `background-color: ${bg};` : colors.buttonBackgroundChosen)}
    }
`

const ContainerButton = styled(Flex)`
    align-items: center;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 4px 10px;
    justify-content: space-between;
    margin-top: 20px;
    transition: all 0.3s ease;
    box-shadow: ${({ openContainer }) =>
        openContainer ? buttonDecoration.mediumBoxshadow : buttonDecoration.lightBoxshadow};
    border: ${({ openContainer }) =>
        openContainer ? buttonDecoration.mediumBorder : buttonDecoration.lightBorder};

    ${({ hover }) =>
        hover
            ? `&:hover {
        cursor: pointer;
    }`
            : ""}
`
const ItemContainer = styled(Flex)`
    flex-direction: column;
    transition: all 0.3s ease;
`

const IconImg = styled(Img)`
    height: ${({ height }) => (height ? height : "30px")};
    width: ${({ width }) => (width ? width : "30px")};
    ${({ disabled }) => (disabled ? `opacity: 0.5; pointer-events: none;` : "")};

    &:hover {
        cursor: pointer;
    }
`

const IconImgContainer = styled(Flex)`
    box-shadow: ${buttonDecoration.veryLightBoxshadow};
    border: ${buttonDecoration.lightBorder};
    height: ${({ height }) => (height ? height : "30px")};
    width: ${({ width }) => (width ? width : "30px")};
    ${({ disabled }) => (disabled ? `opacity: 0.5; pointer-events: none;` : "")};
    border-radius: 4px;
    margin: 0 0 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`
