import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Img, Button, TextArea, CheesyBeanLogo } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

const termsOfService = [
    "Updated March 17, 2023",
    "Rights",
    "◦ You may choose at any time to delete your account within the 'More' section at the bottom of the user info page. When you do so, all your data will be deleted permanently",
    "◦ Sundays reserves the right to delete your account or your data for any reason. If a user reports you or your content for prohibited behavior mentioned below, we will review the report within 24 hours and take whatever action we deem necessary. There is absolutely no tolerance on Sundays for objectionable content or abusive users",
    "Prohibited behavior",
    "◦ We do not allow content that promotes hatred, terrorism, or violence. Additionally, we do not allow any content that is overtly sexual or pornographic. When using this application you must comply with the laws of the country in which you reside. Sundays is not responsable for any action you take that results in legal proceedings. You are not allowed to publish content that infringes upon the intellectual property of other individuals.",
    "Contacting us",
    "◦ For additional questions, please email us at sundaysapphelp@gmail.com",
    "Modification of the Terms of Service",
    "◦ Sundays reserves the right to modify these Terms of Service at any time without explicitly notifying you. Terms of Service and Privacy Policy are always accessible in the user info page.",
]

function ExplanationModal() {
    const { setFirstLogin, firstLogin } = useAuth()
    const nav = useNavigate()

    return (
        <Flex
            flexDirection="column"
            width="330px"
            alignItems="center"
            position="relative"
            minHeight="calc(100vh - 40px)"
            justifyContent="space-between"
            padding="20px"
        >
            {" "}
            <CheesyBeanLogo
                onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(`https://cheesy-bean.com/#/`)
                    nav("/home")
                }}
                marginTop="10px"
            >
                <Text fontWeight="700" mr="0" mt="0" mb="0" textAlign="bottom">
                    Sundays
                </Text>
            </CheesyBeanLogo>
            <RelativeContainer>
                <Text fontWeight="600" fontSize="22px" textAlign="left" mt="14px" mb="8px">
                    Terms of Service
                </Text>
                <Text m="5px 0">{termsOfService[0]}</Text>
                <Text fontWeight="600" textAlign="left" m="12px 0 4px 0">
                    {termsOfService[1]}
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {termsOfService[2]}
                    </Text>
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {termsOfService[3]}
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    {termsOfService[4]}
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {termsOfService[5]}
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    {termsOfService[6]}
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {termsOfService[7]}
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    {termsOfService[8]}
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {termsOfService[9]}
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    {termsOfService[10]}
                </Text>
                <Flex justifyContent="center" alignItem="center" width="100%" marginBottom="40px">
                    <DeleteButton
                        bgc={colors.offGreen}
                        color={colors.white}
                        onClick={(e) => {
                            e.preventDefault()
                            // if (firstLogin) setFirstLogin(false)
                            nav("/contact")
                        }}
                    >
                        <Text m="0" fontWeight="600" fontSize="18px">
                            Go home
                        </Text>
                    </DeleteButton>
                </Flex>
            </RelativeContainer>
        </Flex>
        // </StyledForm>
    )
}

export default ExplanationModal

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    margin-top: 30px;
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    margin-bottom: ${({ mb }) => (mb ? mb : "10px")};
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ bs }) =>
        !bs &&
        `&:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${({ borderColor }) =>
            borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
    }`}
`

const RelativeContainer = styled(Flex)`
    flex-direction: column;
    positon: relative;
    width: 330px;
    align-items: start;
`

const WelcomeImg = styled(Img)`
    position: absolute;
    top: 20px;
    right: -150px;
`

const StyledInput = styled(Input)`
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    padding: 6px 8px;
    font-size: 16px;
`

const InputContainer = styled(Box)`
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) => (opened ? colors.buttonBackgroundChosen : colors.buttonBackground)};
    padding: 12px;
    margin: 14px 0 30px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) => (bg ? bg : colors.buttonBackgroundChosen)};
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
`
