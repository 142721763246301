import React from "react"
import { Flex, Text } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"

function PopupNotificationComponent({ text, bg, id }) {
    return (
        <PopupNotification
            bg={bg}
            className="slide-in-from-above"
            id={id ? id : "popup-notif"}
            zIndex={id === "popup-notif" && 4}
        >
            <Text m="0">{text}</Text>
        </PopupNotification>
    )
}

const PopupNotification = styled(Flex)`
    position: fixed;
    z-index: ${({ zIndex }) => zIndex || 3};
    justify-content: center;
    align-items: center;
    top: 12px;
    align-self: center;
    overflow: auto;
    background-color: ${({ bg }) => bg || colors.offerGreen};
    border-radius: ${buttonDecoration.lightBorderRadius};
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${buttonDecoration.lightBorder};
    padding: 8px 14px;
    display: none;
`

export default PopupNotificationComponent
