import styled from "styled-components"
import {
    color,
    layout,
    typography,
    flexbox,
    border,
    background,
    position,
    shadow,
    grid,
    space,
} from "styled-system"

const Span = styled.span`
    ${color}
    ${layout}
    ${typography}
    ${flexbox}
    ${border}
    ${background}
    ${position}
    ${shadow}
    ${grid}
    ${space}
    box-sizing: border-box;

    ${({ hover }) =>
        hover
            ? `&:hover {
        cursor: pointer;
    }`
            : ""}
`

export default Span
