import React from "react"
import LessOfAnApp from "./LessOfAnApp"
import useWebSocket from "react-use-websocket"
const WS_URL = "ws://10.0.0.241:3001/"
// const WS_URL = "wss://1fda-2601-645-4580-bb30-5e6-930-6ecc-6283.ngrok.io/"

function App() {
    // Connecting to socket on website load, could be cleaner - only when the user is logged in
    // useWebSocket(WS_URL, {
    //     onOpen: () => {
    //         console.log("WebSocket connection established.")
    //     },
    //     onMessage: (event) => {
    //         const { data } = event
    //         console.log(event, "EVENT")
    //         console.log("WebSocket message received:", data)
    //     },
    // })
    return <LessOfAnApp />
}

export default App
