import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Img, Button, TextArea } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function ExplanationModal() {
    const { setFirstLogin, firstLogin, authenticatedUser } = useAuth()

    // async function sendSignUpText() {
    //     try {
    //         const { data } = await axios.get(`/textSignup/?user=${authenticatedUser.number}`, {
    //             withCredentials: true,
    //         })
    //         if (data.error) {
    //             return false
    //         } else {
    //             return true
    //         }
    //     } catch (error) {
    //         console.log(error, "ERROR")
    //         return false
    //     }
    // }

    return (
        // <StyledForm
        //     onSubmit={(e) => {
        //         e.preventDefault()
        //         if (firstLogin) setFirstLogin(false)
        //     }}
        // >
        <RelativeContainer>
            <Text fontWeight="600" fontSize="22px" textAlign="left" pr="110px" mt="14px" mb="8px">
                🎊 Welcome to CheesyBean, a place where beans come true!
            </Text>
            {/* <WelcomeImg src="welcome.svg" /> */}

            <Text fontWeight="600" fontSize="14px">
                Our goal is to help you stay in touch with the closest people in your life. We do this through
                providing a no-frills phone-based platform where your friends and loved ones can share major
                life updates, casual stories, and general thoughts and feelings.
            </Text>
            {/* <Text>The 3 cheesy steps:</Text> */}
            <Flex width="100%" mt="10px" alignItems="center">
                <Img src="numberOne.svg" height="40px" width="40px" mr="17px" />
                <Text fontWeight="600">
                    Write your Bean <Span fontSize="26px">📝</Span>
                </Text>
            </Flex>
            <Text fontSize="14px">
                Every other Sunday at 10pm EST all Beans are due. “Bean” is our shorthand for written life
                updates. Think: a text to an old friend, a blogpost, some thoughts that have been preoccupying
                your mind, or a new comedy routine you’re working on. What your Bean looks like is totally up
                to you!
                {/* Make sure to get your Bean
                in on time, because the next set of Beans won’t go out for 2
                more weeks. */}
            </Text>
            {/* <Text fontSize="12px" fontWeight="300" color={colors.gray}>
                Don't worry, you’ll receive text reminders beforehand.
            </Text> */}
            <Flex width="100%" mt="10px" alignItems="center">
                <Img src="numberTwo.svg" height="40px" width="40px" mr="17px" />
                <Text fontWeight="600" maxWidth="240px">
                    Check your friends’ Beans <Span fontSize="26px">😁</Span>
                </Text>
            </Flex>
            <Text fontSize="14px">
                Once Bean Time has passed, you’ll receive a text announcing the arrival of all your friends’
                Beans. From there, you can login and read about what everyone’s been up to in the past two
                weeks.
                {/* If
                anything a friend writes catches your eye, you can call or text
                them to catch up. 
                What better time to reach out and keep the
                conversation going? */}
            </Text>
            {/* <Text fontSize="12px" fontWeight="300" color={colors.gray}>
                This makes every other Sunday an occasion to look forward to!
            </Text> */}
            <Flex width="100%" mt="10px" alignItems="center">
                <Img src="numberThree.svg" height="40px" width="40px" mr="17px" />
                <Text fontWeight="600">
                    Connect with those closest to you <Span fontSize="26px">🤟</Span>
                </Text>
            </Flex>
            <Text fontSize="14px">
                Add whoever you hope to keep in touch with! Remember, Beans are only readable by friends
                you’ve accepted. This is to make sure your Bean is only seen by the people you wrote it for.
                Even if you shared the link, users who you are not friends with can't read your Bean.
            </Text>
            <Text fontWeight="600" fontSize="14px" mt="18px">
                CheesyBean isn’t a competition or a performance, it’s a platform for genuine connection. We
                recommend adding people you can be honest with :)
            </Text>
            {/* 
            <Text fontWeight="600" fontSize="14px" mt="18px">
                You will receive a text once this button is clicked asking for permission to text you!
            </Text> 
            */}
            <DeleteButton
                bgc={colors.white}
                // color={colors.white}
                color={colors.black}
                onClick={(e) => {
                    e.preventDefault()
                    if (firstLogin) {
                        setFirstLogin(false)
                        // Send texts!
                        // sendSignUpText()
                    }
                }}
            >
                <Text m="0" fontWeight="600" fontSize="18px">
                    Go home
                </Text>
            </DeleteButton>
            <Text fontSize="12px" fontWeight="300" color={colors.gray}>
                You can always click the question mark in the bottom right corner to see this again!
            </Text>
            <Flex width="100%" mt="10px" alignItems="center">
                <Img src="supportText.png" height="40px" width="40px" mr="17px" />
                <Text fontWeight="600">
                    Support{" "}
                    <StyledSpan
                        lineHeight="40px"
                        onClick={(e) => {
                            e.stopPropagation()
                            Object.assign(document.createElement("a"), {
                                href: `sms:+1${2543454978}?&body=${`Hi! I have a question about CheesyBean 🧐. `}`,
                            }).click()
                        }}
                        bg={colors.offerBlue}
                        color="white"
                        fontWeight="600"
                        fontSize="18px"
                        style={{ display: "inline" }}
                    >
                        text us!
                    </StyledSpan>
                </Text>
            </Flex>
            <Text fontSize="14px" mb="40px">
                If you have feature requests, questions, or feedback, please text us! We love hearing from our
                amazing users.
            </Text>
        </RelativeContainer>
        // </StyledForm>
    )
}

export default ExplanationModal

// const StyledForm = styled.form`
//     display: flex;
//     box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
//         inset -2px -2px #808080, inset 2px 2px #dfdfdf;
//     padding: 20px 15px;
//     background: #dfdfdf;
//     width: 300px;
//     box-sizing: border-box;
//     flex-direction: column;
//     transition: height 0.3s ease;
// `

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    margin-top: ${({ m }) => (m ? m : "30px")};
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    margin-bottom: ${({ mb }) => (mb ? mb : "10px")};
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ bs }) =>
        !bs &&
        `&:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${({ borderColor }) =>
            borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
    }`}
`

const StyledSpan = styled(Span)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryVeryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;

    ${({ bs }) =>
        !bs &&
        `&:hover {
    cursor: pointer;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${({ borderColor }) =>
        borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
}`};
`

const RelativeContainer = styled(Flex)`
    flex-direction: column;
    positon: relative;
    width: 100%;
    align-items: center;
`

const WelcomeImg = styled(Img)`
    position: absolute;
    top: 20px;
    right: -150px;
`

const StyledInput = styled(Input)`
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    padding: 6px 8px;
    font-size: 16px;
`

const InputContainer = styled(Box)`
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) => (opened ? colors.buttonBackgroundChosen : colors.buttonBackground)};
    padding: 12px;
    margin: 14px 0 30px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) => (bg ? bg : colors.buttonBackgroundChosen)};
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
`
