import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "../axios"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import useWebSocket, { ReadyState } from "react-use-websocket"
// const socketUrl = "ws://10.0.0.241:3001/"
const socketUrl = "wss://server.cheesy-bean.com/"

const AuthContext = createContext()

export default function AuthProvider({ children }) {
    const queryParams = new URLSearchParams(useLocation().search)
    const user = queryParams.get("user")
    const [loadingInitial, setLoadingInitial] = useState(false)
    const [loginFailed, setLoginFailed] = useState(false)
    const [authenticatedUser, setAuthenticatedUser] = useState({})
    const [inVerifyMode, setInVerifyMode] = useState(false)
    const [firstLogin, setFirstLogin] = useState(false)
    const [connectionId, setConnectionId] = useState("")
    const [textSubscribeNotif, setTextSubscribeNotif] = useState(false)

    const nav = useNavigate()

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log("WebSocket connection established.")
        },
        onMessage: (event) => {
            if (event.data.includes("userId")) {
                setConnectionId(event.data.split("connection userId:")[1])
            } else if (event.data.includes("reauthenticate")) {
                setAuthenticatedUser({ ...authenticatedUser, subscribedOnText: true })
            }
        },
    })

    const sendWebsocketMessage = useCallback(
        (message) => {
            sendMessage(message)
        },
        [sendMessage]
    )

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: "Connecting",
    //     [ReadyState.OPEN]: "Open",
    //     [ReadyState.CLOSING]: "Closing",
    //     [ReadyState.CLOSED]: "Closed",
    //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    // }[readyState]
    // console.log(connectionStatus, "CONN STATUS")

    const authenticate = async () => {
        const path = window.location.href
        try {
            let API_URL = "/getLoggedInUser"

            axios
                .get(API_URL, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.status === 200 && response.data.success) {
                        setAuthenticatedUser({
                            number: response.data.number.slice(2),
                            name: response.data.name,
                            id: response.data.id,
                            subscribed: response.data.subscribed,
                            subscribedOnText: response.data.subscribedOnText,
                            connectionId: connectionId,
                        })
                        sendWebsocketMessage(`usersNumber: ${response.data.number.slice(2)}`)
                        if (!response.data.name) {
                            setFirstLogin(true)
                        }
                        if (!response.data.subscribedOnText) {
                            setTextSubscribeNotif(true)
                        }
                        setLoadingInitial(false)
                        if (!path.includes("bean") && !path.includes("profile")) {
                            nav("/home")
                        }
                    } else {
                        setAuthenticatedUser({})
                        setLoginFailed(true)
                        setInVerifyMode(false) // so user can retry
                        // need to delete the cookie so we can only do a request if they have an untested stytch cookie
                        setLoadingInitial(false)
                        if (!path.includes("bean") && !path.includes("profile")) {
                            nav(`/${user ? `?user=${user}` : ""}`)
                        }
                    }
                })
        } catch (err) {
            setLoadingInitial(false)

            console.error("Error authenticating magic link")
        }
    }

    useEffect(() => {
        const authenticate = async () => {
            const path = window.location.href
            try {
                let API_URL = "/getLoggedInUser"

                axios
                    .get(API_URL, {
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (response.status === 200 && response.data.success) {
                            setAuthenticatedUser({
                                number: response.data.number.slice(2),
                                name: response.data.name,
                                id: response.data.id,
                                subscribed: response.data.subscribed,
                                subscribedOnText: response.data.subscribedOnText,
                                connectionId: connectionId,
                            })
                            sendWebsocketMessage(`usersNumber: ${response.data.number.slice(2)}`)
                            if (!response.data.name) {
                                setFirstLogin(true)
                            }
                            if (!response.data.subscribedOnText) {
                                setTextSubscribeNotif(true)
                            }
                            setLoadingInitial(false)
                            if (!path.includes("bean") && !path.includes("profile")) {
                                nav("/home")
                            }
                        } else {
                            setAuthenticatedUser({})
                            setLoginFailed(true)
                            setInVerifyMode(false) // so user can retry
                            // need to delete the cookie so we can only do a request if they have an untested stytch cookie
                            setLoadingInitial(false)
                            if (!path.includes("bean") && !path.includes("profile")) {
                                nav(`/${user ? `?user=${user}` : ""}`)
                            }
                        }
                    })
            } catch (err) {
                setLoadingInitial(false)

                console.error("Error authenticating magic link")
            }
        }
        // authenticate()
    }, [])

    useEffect(() => {
        if (connectionId) {
            setAuthenticatedUser({ ...authenticatedUser, connectionId: connectionId })
            // sendWebsocketMessage(`usersNumber: ${authenticatedUser.number}`)
        }
    }, [connectionId])

    // async function logout() {
    //     let res = await axios.get("/signOutUser", { withCredentials: true })
    //     if (res.status === 200 && res.data.success) {
    //         setAuthenticatedUser({})
    //         setInVerifyMode(false)
    //         nav("/")
    //     }
    // }

    const memoedValue = useMemo(
        () => ({
            authenticatedUser,
            loadingInitial,
            setAuthenticatedUser,
            loginFailed,
            inVerifyMode,
            // logout,
            firstLogin,
            setFirstLogin,
            authenticate,
            textSubscribeNotif,
            setTextSubscribeNotif,
        }),
        [
            authenticatedUser,
            loadingInitial,
            setAuthenticatedUser,
            loginFailed,
            inVerifyMode,
            // logout,
            firstLogin,
            setFirstLogin,
            authenticate,
            textSubscribeNotif,
            setTextSubscribeNotif,
        ]
    )

    return (
        <AuthContext.Provider value={memoedValue}>
            {loadingInitial ? <BeanSpinnerDontAsk /> : children}
        </AuthContext.Provider>
    )
}

function BeanSpinnerDontAsk() {
    return (
        <Flex alignItems="center" justifyContent="center" minHeight="calc(100vh - 40px)">
            <Img
                src="./CheesyBeanLogo.png"
                maxWidth="100px"
                height="30px"
                className="rotating-bean-dont-ask"
            />
        </Flex>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
