import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Img, Button, TextArea, CheesyBeanLogo } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function ExplanationModal() {
    const { setFirstLogin, firstLogin } = useAuth()
    const nav = useNavigate()

    return (
        <Flex
            flexDirection="column"
            width="330px"
            alignItems="center"
            position="relative"
            minHeight="calc(100vh - 40px)"
            justifyContent="space-between"
            padding="20px"
        >
            {" "}
            <CheesyBeanLogo
                onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(`https://cheesy-bean.com/#/`)
                    nav("/home")
                }}
                marginTop="10px"
            >
                <Text fontWeight="700" mr="0" mt="0" mb="0" textAlign="bottom">
                    Sundays
                </Text>
            </CheesyBeanLogo>
            <RelativeContainer>
                <Text fontWeight="600" fontSize="22px" textAlign="left" mt="14px" mb="8px">
                    Privacy Policy
                </Text>
                <Text fontWeight="600" textAlign="left" m="12px 0 4px 0">
                    What information do we collect?
                </Text>
                <Text fontSize="14px">
                    CheesyBean collects as little info as possible, but we do collect a few things to make the
                    website as user friendly & secure as possible:
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ Contact information you provide directly to us, such as your name used for your
                        friends to identify you & phone number
                    </Text>
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ We collect all of the data from you & don't use any 3rd parties.
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    What do we do with the info?
                </Text>
                <Text fontSize="14px">CheesyBean works hard to keep your data safe:</Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ We keep it safe & secure locked up in our database using the best of best practices
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    Do we sell your data?
                </Text>
                <Text fontSize="14px">CheesyBean believes in not monetizing your data:</Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ We do not share or sell any data to anyone ever for any reason
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    Contacting us
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ For additional questions about this privacy policy or our privacy practices, please
                        text us at <Span fontWeight="600">(254)345-4978</Span>
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    Updates to this policy
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ By accessing any part of CheesyBean's website, you consent to and agree with this
                        privacy policy. CheesyBean may change or revise this privacy policy at any time at our
                        sole discretion. If we make changes to this policy, we will notify you here that it
                        has been updated, so that you are aware of what information we collect, how we use it,
                        and under what circumstances. If you do not agree with any part of this privacy
                        policy, please do not use this website. Continued use of this website signifies your
                        agreement to any updates or changes to our privacy policy or terms and conditions of
                        use.
                    </Text>
                </Flex>
                <Text fontWeight="600" textAlign="left" m="20px 0 4px 0">
                    Effective Date
                </Text>
                <Flex flexDirection="column">
                    <Text fontSize="14px" textAlign="left" m="12px 0 4px 0">
                        {" "}
                        ◦ This policy is effective as of January 9th, 2023.
                    </Text>
                </Flex>
                <Flex justifyContent="center" alignItem="center" width="100%" marginBottom="40px">
                    <DeleteButton
                        bgc={colors.offGreen}
                        color={colors.white}
                        onClick={(e) => {
                            e.preventDefault()
                            // if (firstLogin) setFirstLogin(false)
                            nav("/contact")
                        }}
                    >
                        <Text m="0" fontWeight="600" fontSize="18px">
                            Go home
                        </Text>
                    </DeleteButton>
                </Flex>
            </RelativeContainer>
        </Flex>
        // </StyledForm>
    )
}

// "*Updated March 17, 2023",
// "*What information do we collect?",
// "◦ Sundays collects as little info as possible, but we do collect a few things that are necessary for the core functionality of the app",
// "◦ We collect contact information you provide directly to us, such as your name and phone number. We use your phone number to log you in with our secure one-time-passcode authorization and to allow your friends to find you. Your name is used for your friends to identify you",
// "◦ You can choose to link your phone's contacts to find your friends on Sundays.",
// "*What do we do with the info?",
// "◦ Sundays works hard to keep your data safe. We only store your data in our secure database",
// "◦ We do not allow any third parties to access your data.",
// "◦ You may choose at any time to delete your account within the 'More' section at the bottom of the user info page. When you do so, all your data will be deleted permanently",
// "*Do we sell your data",
// "◦ Sundays does not monetize your data in any way",
// "*Contacting us",
// "◦ For additional questions about this privacy policy or our privacy practices, please email us at sundaysapphelp@gmail.com",
// "*Updates to this policy",
// "◦ By accessing any part of Sundays's application, you consent to and agree with this privacy policy. Sundays may change or revise this privacy policy at any time at our sole discretion. If we make changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances. If you do not agree with any part of this privacy policy, please do not use this application. Continued use of this application signifies your agreement to any updates or changes to our privacy policy or terms and conditions of use.",

export default ExplanationModal

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    margin-top: 30px;
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    margin-bottom: ${({ mb }) => (mb ? mb : "10px")};
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ bs }) =>
        !bs &&
        `&:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${({ borderColor }) =>
            borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
    }`}
`

const RelativeContainer = styled(Flex)`
    flex-direction: column;
    positon: relative;
    width: 330px;
    align-items: start;
`

const WelcomeImg = styled(Img)`
    position: absolute;
    top: 20px;
    right: -150px;
`

const StyledInput = styled(Input)`
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    padding: 6px 8px;
    font-size: 16px;
`

const InputContainer = styled(Box)`
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) => (opened ? colors.buttonBackgroundChosen : colors.buttonBackground)};
    padding: 12px;
    margin: 14px 0 30px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) => (bg ? bg : colors.buttonBackgroundChosen)};
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
`
