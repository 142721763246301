import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, TextArea } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import buttonDecoration from "../buttonDecoration"

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function NamePage({
    setPopupNotifColor,
    setPopupNotifText,
    title,
    page,
    insideTitleRemoved,
    setUpdatingName,
}) {
    const { authenticatedUser, setAuthenticatedUser } = useAuth()

    const [name, setName] = useState(authenticatedUser.name ? authenticatedUser.name : "")
    const [openedItem, setOpenedItem] = useState("")
    const [updateNameError, setUpdateNameError] = useState("")
    const [loading, setLoading] = useState(false)

    const nav = useNavigate()

    async function sendSignUpText() {
        try {
            const { data } = await axios.get(`/textSignup/?user=${authenticatedUser.number}`, {
                withCredentials: true,
            })
            if (data.error) {
                return false
            } else {
                return true
            }
        } catch (error) {
            console.log(error, "ERROR")
            return false
        }
    }

    async function addOrUpdateName(e) {
        e.preventDefault()
        try {
            const { data } = await axios.post(`/updateName`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    usersName: name,
                },
            })
            if (data.error) {
                setUpdateNameError("Failed to update your name. Please try again :(")
            } else {
                if (!authenticatedUser.name) {
                    sendSignUpText()
                }
                setAuthenticatedUser({
                    ...authenticatedUser,
                    name,
                })
                makePopupNotif(
                    setPopupNotifColor,
                    setPopupNotifText,
                    colors.offerGreen,
                    "Saved!",
                    5000,
                    page === "profile" ? "popup-notif" : "popup-notif-dashboard"
                )
                setUpdatingName(false)
            }
        } catch (error) {
            setUpdateNameError("Failed to update your name :(")
            console.log(error, "ERROR")
        }
    }

    useEffect(() => {
        if (!authenticatedUser.number) {
            nav("/")
        }
    }, [authenticatedUser.number, nav])

    useEffect(() => {
        const input = document.getElementById("name-input")
        if (input) input.focus()
    }, [])

    return (
        <Flex flexDirection="column" alignItems="center" marginTop={title ? "0px" : "30px"}>
            <Text m="12px 0 0 0" padding="0 0 6px 0" fontWeight="600" name={"name-input"} alignSelf="start">
                {title ? title : ""}
            </Text>
            <StyledForm onSubmit={addOrUpdateName}>
                {!insideTitleRemoved && (
                    <Text m="0 0 20px 0" fontWeight="600" textAlign="center">
                        Enter a name so your friends can identify you
                    </Text>
                )}
                <StyledInput
                    type="text"
                    id="name-input"
                    name="name field"
                    autoFocus
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    placeholder="Name"
                    disabled={loading}
                />
                <Flex>
                    <DeleteButton
                        type="submit"
                        disabled={loading || !name}
                        mr={title === "Update your name" ? "20px" : "0"}
                    >
                        {" "}
                        <Text color={colors.white} fontSize="20px" fontWeight="600" m="0">
                            Save
                        </Text>
                    </DeleteButton>
                    {title === "Update your name" && (
                        <DeleteButton
                            disabled={loading || !name}
                            bgc={colors.lightGray}
                            onClick={(e) => {
                                e.preventDefault()
                                setUpdatingName(false)
                            }}
                        >
                            <Text color={colors.white} fontSize="20px" fontWeight="600" m="0">
                                Cancel
                            </Text>
                        </DeleteButton>
                    )}
                </Flex>
            </StyledForm>
            {title !== "Update your name" && (
                <Text fontSize="14px" textAlign="center" color={colors.gray}>
                    You will receive an IMPORTANT text to opt in to friend request notifs & reminders! 🥳
                </Text>
            )}
        </Flex>
    )
}

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text, time, id) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById(id).style.display = "flex"
    setTimeout(() => {
        const notif = document.getElementById(id)
        notif.style.display = "none"
    }, time)
}

export default NamePage

const StyledForm = styled.form`
    display: flex;
    box-shadow: ${buttonDecoration.veryLightBoxshadow};
    padding: 20px 15px;
    background: ${colors.offerBlue};
    border: ${buttonDecoration.lightBorder};
    border-radius: 8px;
    max-width: 330px;
    min-wdith: 310px;
    box-sizing: border-box;
    flex-direction: column;
    transition: all 0.3s ease;
    align-items: center;
`

const StyledInput = styled(Input)`
    box-shadow: ${buttonDecoration.veryLightBoxshadow};
    border-radius: 6px;
    width: 100%;
    border: ${buttonDecoration.lightBorder};
    padding: 6px 8px;
    font-size: 20px;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const DeleteButton = styled(Button)`
    box-shadow: ${buttonDecoration.veryLightBoxshadow};
    background-color: ${({ bgc }) => (bgc ? bgc : colors.offerGreen)};
    border: ${buttonDecoration.lightBorder};
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 18px;
    font-size: 18px;
    width: fit-content;
    transition: all 0.3s ease;
    ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}

    &:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${buttonDecoration.mediumBorder};
    }
`
