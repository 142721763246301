import React, { useEffect, useState, useLayoutEffect, memo, useCallback } from "react"
import { useSearchParams, useNavigate, useLocation, useParams, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img, CheesyBeanLogo, A } from "../components"
import styled, { keyframes } from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import { useAuth } from "../context/AuthContext"

function LoginPage() {
    const nav = useNavigate()
    const { authenticatedUser } = useAuth()

    const appLink = "https://apps.apple.com/us/app/sundays/id6446004330"

    const goToAppStore = async () => {
        window.location.replace(appLink)
    }

    useEffect(() => {
        if (authenticatedUser.number) {
            nav("/home")
        }
    }, [])

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            padding="20px"
            minHeight="calc(100vh - 40px)"
        >
            <Flex flexDirection="column" alignItems="center">
                <LogoButton onClick={goToAppStore}>
                    <Text fontWeight="600" mt="0" mb="0" textAlign="bottom" fontSize="22px">
                        Sundays
                    </Text>
                </LogoButton>
                <Text fontWeight="500" fontSize="16px" textAlign="center" verticalAlign="center">
                    Your friends unfiltered <Span fontSize="24px">🤝</Span>
                </Text>
            </Flex>
            <AppButtonContainer onClick={goToAppStore}>
                <Text m="0" fontWeight="400" mb="10px" fontSize="16px" color={colors.darkGray}>
                    Get early access
                </Text>
                <NiceImg src="app-store-logo.png" />
            </AppButtonContainer>
            <Flex flexDirection="column" alignItems="center">
                <Flex>
                    <StyledExternalLink href="mailto:help@sundays.social">Email us</StyledExternalLink>
                    <StyledLink to="/privacy-policy/">Privacy</StyledLink>
                    <StyledLink to="/terms-of-service/">Terms</StyledLink>
                </Flex>
                <Text textAlign="center" color={colors.gray} mt="30px" mb="4px">
                    <Span fontSize="20px">©</Span> Spill The Beans Inc. 2023
                </Text>
            </Flex>
        </Flex>
    )
}

const AppButtonContainer = styled(Flex)`
    align-items: center;
    flex-direction: column;
    padding: 30px;

    &:hover {
        ${() => `${NiceImg} {         box-shadow: none;
        transform: translate(3px, 3px); }`}
        cursor: pointer;
    }
`

const LogoButton = styled(Flex)`
    border-radius: 60px;
    background-color: #f8f275;
    padding: 8px 14px 8px 14px;
    align-items: center;
    margin-top: 20px;
    border: 1px solid;
    box-shadow: 3px 3px 0;
    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

const NiceImg = styled(Img)`
    border-radius: 12px;
    box-shadow: 3px 3px 0;
    max-width: 370px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 200px;
    background-color: white;
    border: 1px solid;
    padding: 10px 14px;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text, time, id) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById(id).style.display = "flex"
    setTimeout(() => {
        let notif = document.getElementById(id)
        if (notif) notif.style.display = "none"
    }, time)
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${colors.darkGray};
    font-weight: 400;
    margin: 4px 18px 0 0;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        color: ${colors.medGray};
        cursor: pointer;
    }
`

const StyledExternalLink = styled(A)`
    text-decoration: none;
    color: ${colors.darkGray};
    font-weight: 400;
    margin: 4px 18px 0 0;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        color: ${colors.medGray};
        cursor: pointer;
    }
`

const StyledButton = styled(Text)`
    text-decoration: none;
    color: ${colors.gray};
    font-weight: 400;
    margin: 4px 14px 0 0;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
        color: ${colors.medGray};
        cursor: pointer;
    }
`

export default LoginPage
