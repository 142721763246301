import React, { useState } from "react"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2).match(/\d/g).join("")
    } else {
        usersNumber = number
    }
    return usersNumber
}

function QuestionMarkExplainer() {
    const { setTextSubscribeNotif } = useAuth()

    return (
        <>
            <StyledConfirmationWindow
                className="explainer-window"
                id="explainer-window"
                onClick={(e) => {
                    if (e.target.classList.contains("explainer-window")) {
                        document.getElementById("explainer-window").style.display = "none"
                        setTextSubscribeNotif(false)
                    }
                }}
            >
                <ExplainerContainer>
                    <Text fontWeight="600" fontSize="20px" textAlign="center">
                        Final step of sign up!
                    </Text>
                    <Text m="0" textAlign="center">
                        Send us a text that says "Y"! 💬
                    </Text>
                    <DeleteButton
                        onClick={(e) => {
                            e.stopPropagation()
                            Object.assign(document.createElement("a"), {
                                href: `sms:+1${2543454978}?&body=${`Y`}`,
                            }).click()
                        }}
                        fontWeight="600"
                        fontSize="18px"
                        color={colors.white}
                        bgc={colors.offerGreen}
                        mb="12px"
                        alignSelf="center"
                    >
                        Click to text "Y"
                    </DeleteButton>
                    <Text fontSize="11px" m="0" color={colors.lightGray}>
                        This will allow you to receive friend request notifs, Bean time announcements, &
                        reminders! 🥳
                    </Text>
                </ExplainerContainer>
            </StyledConfirmationWindow>
        </>
    )
}

const QuestionMarkExplainerStyle = styled(Flex)`
    position: fixed;
    bottom: 12px;
    right: 12px;
`

const StyledConfirmationWindow = styled(Flex)`
    position: fixed;
    z-index: 3;
    justify-content: center;
    align-items: start;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(2px);
    display: flex;
`
const ExplainerContainer = styled(Flex)`
    flex-direction: column;
    width: 320px;
    overflow: scroll;
    background-color: ${colors.white};
    margin-top: 60px;
    padding: 0 20px 20px 20px;
    background-color: white;
    box-shadow: ${buttonDecoration.mediumBoxshadow};
    border: ${buttonDecoration.mediumBorder};
    border-radius: 8px;
`

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    margin-top: 12px;
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: ${({ padding }) => (padding ? padding : "8px 16px")};
    transition: all 0.3s ease;
    margin-bottom: ${({ mb }) => (mb ? mb : "20px")};
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ bs }) =>
        !bs &&
        `&:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${({ borderColor }) =>
            borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
    }`}
`

const StyledSpan = styled(Span)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;
    margin-right: 10px;

    ${({ bs }) =>
        !bs &&
        `&:hover {
    cursor: pointer;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${({ borderColor }) =>
        borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
}`};
`

export default QuestionMarkExplainer
