const Colors = {
    // purple: "#5E5DF0",
    // purpleBlue: "linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%)",
    // bluePurple: "linear-gradient(135deg, #4BE1EC 0%, #CB5EEE 100%)",
    // yellowPink: "linear-gradient(135deg, #FFCC33 0%, #E233FF 100%)",
    // blueGreen: "linear-gradient(135deg, #2F80ED 0%, #B2FFDA 100%)",
    black: "#161616",
    darkGray: "#2c2c30",
    medGray: "#414145",
    gray: "#6d6d6d",
    lightGray: "#8a8f98",
    veryLightGray: "#d1d1d1",
    veryVeryLightGray: "#e7e7e7",
    veryVeryVeryLightGray: "#f2f2f2",
    white: "#ffffff",
    backgroundGray: "#c1c9d2",
    buttonBackground: "#dfdfdf",
    buttonBackgroundChosen: "#CCCCCC",
    wordsNotChosen: "#666670",
    wordsChosen: "#30313D",
    cheese: "#FDCE95",
    lightCheese: "#fbd3a2",
    lightGreen: "#00c29a",
    // lightPurple: "#c89afe",
    // lightBlue: "#4ea2ff",
    // lightYellow: "#f8fd99",
    errorRed: "#f54842",
    cheesyBeanYellow: "#F8F275",
    cheesyBeanGreen: "#50FFAF",
    offWhite: "#F7F6DD",
    offOrange: "#F1855A",
    offGreen: "#459B87",
    offRed: "#FF6B6B",
    offBlue: "#7fc2e5",
    offerBlue: "hsl(206deg 100% 73%)",
    offerGreen: "#00c29a",
    offTurqoise: "hsl(180deg 51% 52%)",
    offPurple: "hsl(242deg 88% 66% / 50%)",
    brightPurple: "#6E6AFF",
    brightRed: "#FF6B6B",
}

export default Colors
