import { A } from "../components"
import colors from "../colors"
import styled from "styled-components"

const StyledLink = styled(A)`
    text-decoration: none;
    color: ${colors.wordsNotChosen};
    padding: 8px 10px;
    background-color: ${colors.veryVeryLightGray};
    display: flex;
    width: max-content;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    margin-top: 8px;
    ${({ mr }) => (mr ? `margin-right: ${mr};` : "")}
`

export default StyledLink
