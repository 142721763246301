import React, { useEffect, useState, useLayoutEffect, memo, useCallback } from "react"
import { useSearchParams, useNavigate, useLocation, useParams, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img, CheesyBeanLogo, A } from "../components"
import styled, { keyframes } from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import PopupNotification from "../components/PopupNotification"

function fuck(str) {
    str = str.replace(/\s+/g, "")
    if (str.length > 2) {
        str = str.slice(0, 3) + " " + str.slice(3)
    }
    if (str.length > 6) {
        str = str.slice(0, 7) + " " + str.slice(7)
    }
    return str
}

function phoneFormat(input) {
    //returns (###) ###-####
    input = input.replace(/\D/g, "")
    var size = input.length
    if (size > 0) {
        input = "(" + input
    }
    if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
    }
    if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
    }
    return input
}

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function LoginPage() {
    const nav = useNavigate()
    const { authenticatedUser } = useAuth()

    useEffect(() => {
        if (authenticatedUser.number) {
            nav("/home")
        }
    }, [])

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            padding="20px"
            minHeight="calc(100vh - 40px)"
        >
            <Flex flexDirection="column" alignItems="center">
                <CheesyBeanLogo />
                <LoginComponent />
                <Text mt="40px" fontWeight="600" fontSize="14px" p="0 14px" textAlign="center">
                    A platform for personal newsletters for you and your closest friends.
                </Text>
            </Flex>
            <StyledLink to="/privacy-policy/" fontWeight="400" color={colors.gray} m="0">
                Privacy policy
            </StyledLink>
        </Flex>
    )
}

export const LoginComponent = ({ title }) => {
    const [numberOne, setNumberOne] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const [usersNumber, setUsersNumber] = useState(searchParams.get("user") ? searchParams.get("user") : "")
    const [numberInParams, setNumberInParams] = useState(
        searchParams.get("user") ? searchParams.get("user") : false
    )
    const [phoneId, setPhoneId] = useState("")
    const [notAUser, setNotAUser] = useState(false)
    const [loading, setCodeLoading] = useState(false)
    const [loggedInUserLoading, setLoggedInUserLoading] = useState(false)
    const [sixDigitCodeError, setSixDigitCodeError] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    const [codeToNumberCodeError, setCodeToNumberCodeError] = useState(false)
    const location = useLocation()
    const beanId = location.pathname.split("/bean/")[1]
    const profileId = location.pathname.split("/profile/")[1]
    const [popupNotifText, setPopupNotifText] = useState("")
    const [popupNotifColor, setPopupNotifColor] = useState("")
    const [popupNotifTextFirst, setPopupNotifTextFirst] = useState("")
    const [popupNotifColorFirst, setPopupNotifColorFirst] = useState("")
    const [cheeseShooterArr, setCheeseShooterArr] = useState([])
    const nav = useNavigate()
    const {
        authenticatedUser,
        setAuthenticatedUser,
        loginFailed,
        inVerifyMode,
        loadingInitial,
        setFirstLogin,
        setTextSubscribeNotif,
    } = useAuth()

    function isValidPasscodeDigit(digitValue) {
        const regex = /^\d{6}$/gm
        if (digitValue.match(regex)) {
            return true
        }
        return false
    }

    function isValidNumber(number) {
        // Regex validates phone numbers in (xxx)xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, and xxx.xxx.xxxx format
        const regex = /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/g
        if (number.match(regex)) {
            return true
        }
        return false
    }

    async function handleSubmit(e) {
        if (e) e.preventDefault()
        setCodeLoading(true)
        try {
            const code = `${numberOne}`
            const resp = await axios({
                method: "post",
                url: `/authenticate`,
                data: {
                    code,
                    phoneId,
                },
            })
            if (resp.data.success) {
                setAuthenticatedUser({
                    name: resp.data.name,
                    number: resp.data.number.slice(2),
                    id: resp.data.id,
                    subscribed: resp.data.subscribed,
                    subscribedOnText: resp.data.subscribedOnText,
                })
                if (!resp.data.name) {
                    setFirstLogin(true)
                }
                if (!resp.data.subscribedOnText) {
                    setTextSubscribeNotif(true)
                }
                if (!beanId && !profileId) {
                    nav(`/home`)
                }
                setCodeLoading(false)
                makePopupNotif(
                    setPopupNotifColor,
                    setPopupNotifText,
                    colors.offGreen,
                    "Success!",
                    5000,
                    "popup-notif"
                )
            } else {
                setErrorMessage("Not a good code :(")
                setNumberOne("")
                setSixDigitCodeError(true)
                setCodeLoading(false)
                makePopupNotif(
                    setPopupNotifColor,
                    setPopupNotifText,
                    colors.offRed,
                    "Not a good code :(",
                    5000,
                    "popup-notif"
                )
            }
        } catch (error) {
            setSixDigitCodeError(true)
            setCodeLoading(false)
            makePopupNotif(
                setPopupNotifColor,
                setPopupNotifText,
                colors.offRed,
                "Failed to send code :(",
                5000,
                "popup-notif"
            )
        }
    }
    async function sendCode() {
        setCodeSent(true)
        makePopupNotif(
            setPopupNotifColorFirst,
            setPopupNotifTextFirst,
            colors.offerGreen,
            "A code is being sent",
            2000,
            "popup-notif-first"
        )
        try {
            const { data } = await axios.get(`/loginorcreate?user=${usersNumber.replace(/\D/g, "")}`, {
                withCredentials: true,
            })
            if (phoneNumberError) setPhoneNumberError(false)
            if (!data.error) {
                setPhoneId(data.phoneId)
                if (notAUser) {
                    setNotAUser(false)
                }
                setCodeToNumberCodeError(false)
                setCodeSent(false)
                makePopupNotif(
                    setPopupNotifColor,
                    setPopupNotifText,
                    colors.offerGreen,
                    `A code has been sent to ${
                        usersNumber.includes(")") ? usersNumber : formatNumber(usersNumber)
                    }!`,
                    10000,
                    "popup-notif"
                )
            } else {
                setCodeToNumberCodeError(true)
                setNotAUser(true)
                if (phoneId) {
                    setPhoneId(false)
                }
                setCodeSent(false)
                makePopupNotif(
                    setPopupNotifColor,
                    setPopupNotifText,
                    colors.offRed,
                    "A code could not be sent right now :(",
                    5000,
                    "popup-notif"
                )
            }
        } catch (error) {
            setCodeToNumberCodeError(true)
            setCodeSent(false)
            setPhoneNumberError(true)
            setNumberOne("")
            makePopupNotif(
                setPopupNotifColor,
                setPopupNotifText,
                colors.offRed,
                "A code could not be sent right now :(",
                5000,
                "popup-notif"
            )
        }
    }

    useLayoutEffect(() => {
        if (numberInParams && usersNumber && isValidNumber(usersNumber)) {
            sendCode()
        } else {
            console.log("not a valid number")
        }
    }, [])

    useEffect(() => {
        const number = usersNumber.replace(/\D/g, "")
        if (number && number.length === 10 && isValidNumber(number) && !phoneId && !numberInParams) {
            sendCode().then(() => {
                // const cont = document.getElementById("one-time-code-container")
                // const input = document.getElementById("one-time-code")
                // cont.style.display = "flex"
                // if (input) input.focus()
            })
        }
    }, [usersNumber])

    useEffect(() => {
        function handleOTP(e) {
            // Essentially none of this works on safari
            // Some how the OTP code is still an option on the keyboard
            const input = document.querySelector('input[autocomplete="one-time-code"]')
            if (!input) return
            const ac = new AbortController()
            const form = input.closest("form")
            if (form) {
                form.addEventListener("submit", (e) => {
                    ac.abort()
                })
            }
            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    alert(otp.code)
                    const input = document.getElementById("one-time-code")
                    input.value = otp.code
                    if (form) {
                        form.submit()
                    }
                    if (input) input.focus()
                    setNumberOne(otp.code)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        if ("OTPCredential" in window) {
            window.addEventListener("DOMContentLoaded", handleOTP)
        }
        return () => window.removeEventListener("DOMContentLoaded", handleOTP)
    }, [])

    useEffect(() => {
        if (numberOne && numberOne.length === 6 && isValidPasscodeDigit(numberOne)) {
            handleSubmit()
        }
    }, [numberOne])

    useEffect(() => {
        if (numberInParams || phoneId) {
            const input = document.getElementById("one-time-code")
            if (input) input.focus()
        }
    }, [numberInParams, phoneId])

    return (
        <Flex flexDirection="column" alignItems="center">
            <Flex position="relative" bottom="-70px" left="-13px">
                {cheeseShooterArr?.map((_) => {
                    return (
                        <CheeseShooterComponent key={`${_.id}-cheeseShooter`} time={_.time} x={_.x} y={_.y} />
                    )
                })}
            </Flex>
            <Text
                m="12px 0 0 0"
                padding="0 0 6px 0"
                fontWeight="600"
                name={numberInParams || phoneId ? "one-time-code title" : "telephone phone number title"}
                alignSelf="start"
            >
                {title ? title : "Login"}
            </Text>
            <StyledForm
                onSubmit={handleSubmit}
                openHeight={numberInParams || phoneId ? "90px" : "82px"}
                width={numberInParams || phoneId ? "300px" : "280px"}
            >
                {numberInParams || phoneId ? (
                    <>
                        <InputContainer
                            sixDigit={true}
                            width={"240px"}
                            // display={"none"}
                            id="one-time-code-container"
                        >
                            <input
                                type="text"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                name="one-time-code"
                                id="one-time-code"
                                value={numberOne}
                                className="six-digit-input"
                                autoFocus
                                onChange={(e) => {
                                    setNumberOne(e.target.value.replace(/\D/g, ""))
                                    if (e.target.value.replace(/\D/g, "").length === 6) {
                                        e.target.blur()
                                    }
                                    const timeInMiliseconds = Date.now()
                                    const animationTime = Math.floor(Math.random() * 3000) + 4000
                                    const x =
                                        (Math.random() < 0.5 ? -1 : 1) *
                                        (Math.floor(Math.random() * 1800) + 1000)
                                    const y =
                                        (Math.random() < 0.5 ? -1 : 1) *
                                        (Math.floor(Math.random() * 1800) + 1000)
                                    setCheeseShooterArr((prevValue) => [
                                        ...prevValue,
                                        {
                                            id: timeInMiliseconds,
                                            time: animationTime / 1000,
                                            x,
                                            y,
                                        },
                                    ])

                                    setTimeout(() => {
                                        setCheeseShooterArr((prevValue) =>
                                            prevValue.filter((_) => _.id !== timeInMiliseconds)
                                        )
                                    }, animationTime)
                                }}
                                placeholder="000000"
                                disabled={loading}
                                maxLength="6"
                            />
                        </InputContainer>
                        {codeToNumberCodeError && <ErrorText>The code failed to send! :(</ErrorText>}
                    </>
                ) : (
                    <>
                        <Flex flexDirection="column">
                            <InputContainer sixDigit={false} name="enter your phone number">
                                <NumberInput
                                    id="phoneNumberInput"
                                    maxLength="14"
                                    placeholder="(512) 123-4567"
                                    letterSpacing="3px"
                                    autocomplete="tel-country-code"
                                    inputmode="tel"
                                    type="tel"
                                    name="tel"
                                    autoFocus
                                    onChange={(e) => {
                                        setUsersNumber(e.target.value)
                                        const timeInMiliseconds = Date.now()
                                        const animationTime = Math.floor(Math.random() * 3000) + 4000
                                        const x =
                                            (Math.random() < 0.5 ? -1 : 1) *
                                            (Math.floor(Math.random() * 1800) + 1000)
                                        const y =
                                            (Math.random() < 0.5 ? -1 : 1) *
                                            (Math.floor(Math.random() * 1800) + 1000)
                                        setCheeseShooterArr((prevValue) => [
                                            ...prevValue,
                                            {
                                                id: timeInMiliseconds,
                                                time: animationTime / 1000,
                                                x,
                                                y,
                                            },
                                        ])

                                        setTimeout(() => {
                                            setCheeseShooterArr((prevValue) =>
                                                prevValue.filter((_) => _.id !== timeInMiliseconds)
                                            )
                                        }, animationTime)
                                        // This will allow to focus the 6 digit code input
                                        // Safari will not allow the code to be in the keyboard though :(
                                        // This defeats the purpose of this whole thing - fuck safari
                                        // if (e.target.value.length === 14) {
                                        //     const fakeInput =
                                        //         document.createElement("input")
                                        //     fakeInput.setAttribute(
                                        //         "type",
                                        //         "text"
                                        //     )
                                        //     fakeInput.style.position =
                                        //         "absolute"
                                        //     fakeInput.style.opacity = "0"
                                        //     fakeInput.style.height = "0"
                                        //     fakeInput.style.fontSize = "16px"
                                        //     document.body.prepend(fakeInput)
                                        //     fakeInput.focus()

                                        //     setTimeout(() => {
                                        //         const input =
                                        //             document.getElementById(
                                        //                 "one-time-code"
                                        //             )
                                        //         console.log("INPUT: ", input)
                                        //         if (input) input.focus()
                                        //         fakeInput.remove()
                                        //     }, 6000)
                                        // }
                                    }}
                                    value={phoneFormat(usersNumber)}
                                ></NumberInput>
                            </InputContainer>
                            {notAUser && (
                                <ErrorText>Sorry, you are not a user. Need to be invited! :(</ErrorText>
                            )}
                            {phoneId && !phoneNumberError && (
                                <Text textAlign="center" fontSize="13px" color="#17181e" mt="15px">
                                    A code has been sent! :)
                                </Text>
                            )}
                        </Flex>
                    </>
                )}
            </StyledForm>
            {(numberInParams || phoneId) && (
                <Flex
                    onClick={(e) => {
                        e.stopPropagation()
                        setPhoneId(false)
                        setNumberInParams(false)
                        setUsersNumber("")
                    }}
                >
                    <ActiveLetters color="#30313D">Need another code?</ActiveLetters>
                </Flex>
            )}
            <PopupNotification bg={popupNotifColor} text={popupNotifText} id={"popup-notif"} />
            <PopupNotification
                bg={popupNotifColorFirst}
                text={popupNotifTextFirst}
                id={"popup-notif-first"}
            />
        </Flex>
    )
}

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text, time, id) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById(id).style.display = "flex"
    setTimeout(() => {
        let notif = document.getElementById(id)
        if (notif) notif.style.display = "none"
    }, time)
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${colors.gray};
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    margin-top: 4px;

    &:hover {
        text-decoration: underline;
        color: ${colors.medGray};
    }
`

const shootOutRandom = (x, y) => keyframes`
0% {
    transform: translate(0, 0);
}

50% {
    transform: translate(${x / 2}px, ${y / 2}px) rotate(980deg);
}

100% {
    transform: translate(${x}px, ${y}px) rotate(1720deg);
}
`

const CheeseShooter = styled(Img)`
    animation: ${({ x, y }) => shootOutRandom(x, y)} ${({ time }) => time}s ease-in;
    position: absolute;
    height: 32px;
    width: 32px;
`

const CheeseShooterComponent = memo(({ index, time, x, y }) => {
    // Make this component only rerender if a prop changes
    return (
        <CheeseShooter
            src="cheeseIcon.png"
            className="flying-cheese"
            key={index}
            index={index}
            time={time}
            x={x}
            y={y}
        />
    )
})

const StyledForm = styled.form`
    display: flex;
    border-radius: 8px;
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    padding: 20px 20px 20px 20px;
    background-color: ${colors.offerBlue};
    box-sizing: border-box;
    height: ${({ openHeight }) => openHeight && openHeight};
    flex-direction: column;
    transition: all 0.3s ease;
    width: ${({ width }) => (width ? width : "100%")};
`

const InputContainer = styled(Box)`
    display: ${({ display }) => (display ? display : "flex")};
    transition: all 0.3s ease;
    ${({ sixDigit }) =>
        sixDigit
            ? `    box-shadow: 2px 2px 0 0 #000;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    padding: 0 0px 0 26px; width: 258px;`
            : ""}
    position: relative;
`

const ActiveLetters = styled(Text)`
    text-decoration: underline;
    font-size: 14px;
    &:hover {
        cursor: pointer;
    }
    color: #17181e;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const NumberInput = styled(Input)`
    font-size: 19px;
    transition: all 0.3s ease;
    width: ${({ width }) => (width ? width : "100%")};
    box-sizing: border-box;
    height: 40px;
    border: none;
    display: flex;
    padding: 0 5px 0 17px;
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    background-color: ${colors.white};
    border-radius: 8px;
`

export default LoginPage
