import React from "react"
import { useNavigate } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"

function CheesyBeanLogo() {
    const nav = useNavigate()
    return (
        <CheesyBeanLogoStyle
            onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(`https://cheesy-bean.com/#/`)
                nav("/home")
            }}
        >
            <Text fontWeight="700" mr="0" mt="0" mb="0" textAlign="bottom">
                Sundays
            </Text>
        </CheesyBeanLogoStyle>
    )
}

const CheesyBeanLogoStyle = styled(Flex)`
    border-radius: 25px;
    background-color: ${colors.cheesyBeanYellow};
    padding: 10px;
    alignitems: center;
    margin-top: 10px;
    margin-bottom: 25px;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${buttonDecoration.mediumBorder};

    &:hover {
        cursor: pointer;
    }
`
export default CheesyBeanLogo
