import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import axios from "../axios"
import { useAuth } from "./AuthContext"

const FriendContext = createContext()

export default function FriendProvider({ children }) {
    const [friends, setFriends] = useState([])
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([])
    const [sentFriendRequests, setSentFriendRequests] = useState([])
    const [friendContextLoading, setFriendContextLoading] = useState(false)
    const [addFriendError, setAddFriendError] = useState(false)
    const [addFriendLoading, setAddFriendLoading] = useState(false)
    // const [deleteFriendError, setDeleteFriendError] = useState(false)
    const [getFriendsError, setGetFriendsError] = useState(false)
    const [acceptFriendRequestError, setAcceptFriendRequestError] = useState(false)
    const [denyFriendRequestError, setDenyFriendRequestError] = useState(false)
    const [deleteFriendRequestError, setDeleteFriendRequestError] = useState(false)

    // Reminder friend request
    const [friendRequestReminderLoading, setFriendRequestReminderLoading] = useState(false)
    const [friendRequestReminderSuccess, setFriendRequestReminderSuccess] = useState(false)
    // Add error state if there are unique errors such as already friends, already sent friend request, etc
    const [friendRequestReminderError, setFriendRequestReminderError] = useState(false)
    const [deleteFriendRequestLoading, setDeleteFriendRequestLoading] = useState(false)
    const [deleteFriendLoading, setDeleteFriendLoading] = useState(false)
    const [acceptFriendRequestLoading, setAcceptFriendRequestLoading] = useState(false)
    const [denyFriendRequestLoading, setDenyFriendRequestLoading] = useState(false)

    const { authenticatedUser } = useAuth()

    async function getFriendsFunction() {
        if (authenticatedUser.number) {
            try {
                const { data } = await axios.get(`/friends?user=${authenticatedUser.number}`, {
                    withCredentials: true,
                })

                setFriends(data.friends)
                setReceivedFriendRequests(data.receivedFriendRequests)
                setSentFriendRequests(data.sentFriendRequests)
                return true
            } catch (error) {
                setGetFriendsError(true)
                return false
            }
        }
    }

    async function getFriendsOnLoadFunction() {
        setFriendContextLoading(true)
        await getFriends()
        setFriendContextLoading(false)
    }

    async function deleteFriendFunction(friendsNumber) {
        setDeleteFriendLoading(true)
        try {
            const { data } = await axios.delete(`/deleteFriend`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    friendsNumber,
                },
            })
            if (!data.success) {
                // setDeleteFriendError("Failed to delete friend")
                setDeleteFriendLoading(false)
                return false
            } else {
                setFriends(data.friends)
                setDeleteFriendLoading(false)
                return true
            }
        } catch (error) {
            // setDeleteFriendError("Failed to delete friend")
            setDeleteFriendLoading(false)
            return false
        }
    }

    async function deleteFriendRequestFunction(friendsNumber) {
        setDeleteFriendRequestLoading(true)
        try {
            const { data } = await axios.delete(`/deleteFriendRequest`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    friendsNumber,
                },
            })
            if (data.error) {
                setDeleteFriendRequestError("Failed to delete friend")
                setDeleteFriendRequestLoading(false)
                return false
            } else {
                setSentFriendRequests(data.sentFriendRequests)
                setDeleteFriendRequestLoading(false)
                return true
            }
        } catch (error) {
            // setDeleteFriendError("Failed to delete friend request")
            // console.log(error, "ERROR")
            setDeleteFriendRequestLoading(false)
            return false
        }
    }

    async function acceptFriendRequestFunction(friendsNumber) {
        setAcceptFriendRequestLoading(true)
        try {
            const { data } = await axios.post(`/acceptFriendRequest`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    friendsNumber,
                },
            })
            if (data.error) {
                setAcceptFriendRequestError("Failed to accept friend")
                setAcceptFriendRequestLoading(false)
                return false
            } else {
                setFriends(data.friends)
                setReceivedFriendRequests(data.friendRequestsReceived)
                setAcceptFriendRequestLoading(false)
                return true
            }
        } catch (error) {
            console.log("ERROR", error)
            setAcceptFriendRequestLoading(false)
            setAcceptFriendRequestError("Failed to accept friend")
            return false
        }
    }

    async function denyFriendRequestFunction(friendsNumber, setOpenedItem) {
        setDenyFriendRequestLoading(true)
        try {
            const { data } = await axios.post(`/denyFriendRequest`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    friendsNumber,
                },
            })
            if (data.error) {
                // setDeleteFriendError("Failed to delete friend")
                setDenyFriendRequestLoading(false)
                return false
            } else {
                setReceivedFriendRequests(data.friendRequestsReceived)
                setDenyFriendRequestLoading(false)
                return true
            }
        } catch (error) {
            // setDeleteFriendError("Failed to delete friend")
            setDenyFriendRequestLoading(false)
            console.log(error, "ERROR")
            return false
        }
    }

    async function addFriendFunction(setOpenedItem, friendsNumber) {
        try {
            const { data } = await axios.post(`/addFriend`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    friendAddNumber: friendsNumber,
                },
            })
            if (data.error) {
                setAddFriendLoading(false)
                setAddFriendError("Failed to add friend")
                return false
            } else {
                setAddFriendLoading(false)
                setOpenedItem(false)
                setSentFriendRequests(data.friendRequestsSent)
                return true
            }
        } catch (error) {
            setAddFriendError("Failed to add friend")
            setAddFriendLoading(false)
            return false
        }
    }

    async function sendFriendRequestReminderFunction(friendsNumber) {
        try {
            setFriendRequestReminderLoading(true)
            const { data } = await axios.post(`/friendRequestReminder`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    usersName: authenticatedUser.name,
                    friendsNumber,
                },
            })
            if (data.error) {
                setFriendRequestReminderLoading(false)
                setFriendRequestReminderError(data?.error)
                return false
            } else {
                setFriendRequestReminderLoading(false)
                setFriendRequestReminderSuccess(friendsNumber)
                await getFriends()
                return true
            }
        } catch (error) {
            setFriendRequestReminderLoading(false)
            setFriendRequestReminderError(error.message)
            return false
        }
    }

    const addFriend = useCallback(addFriendFunction, [authenticatedUser])
    const deleteFriend = useCallback(deleteFriendFunction, [authenticatedUser])
    const acceptFriendRequest = useCallback(acceptFriendRequestFunction, [authenticatedUser])
    const denyFriendRequest = useCallback(denyFriendRequestFunction, [authenticatedUser])
    const deleteFriendRequest = useCallback(deleteFriendRequestFunction, [authenticatedUser])
    const sendFriendRequestReminder = useCallback(sendFriendRequestReminderFunction, [authenticatedUser])
    const getFriends = useCallback(getFriendsFunction, [authenticatedUser])
    const getFriendsOnLoad = useCallback(getFriendsOnLoadFunction, [getFriends, setFriendContextLoading])

    const memoedValue = useMemo(
        () => ({
            deleteFriend,
            addFriend,
            acceptFriendRequest,
            denyFriendRequest,
            deleteFriendRequest,
            friends,
            getFriends,
            receivedFriendRequests,
            sentFriendRequests,
            friendContextLoading,
            addFriendError,
            // deleteFriendError,
            getFriendsError,
            acceptFriendRequestError,
            denyFriendRequestError,
            deleteFriendRequestError,
            addFriendLoading,
            setAddFriendError,
            setAddFriendLoading,
            // Friend request reminder state
            sendFriendRequestReminder,
            friendRequestReminderLoading,
            friendRequestReminderError,
            friendRequestReminderSuccess,
            deleteFriendRequestLoading,
            deleteFriendLoading,
            acceptFriendRequestLoading,
            denyFriendRequestLoading,
        }),
        [
            deleteFriend,
            addFriend,
            acceptFriendRequest,
            denyFriendRequest,
            deleteFriendRequest,
            friends,
            getFriends,
            receivedFriendRequests,
            sentFriendRequests,
            friendContextLoading,
            addFriendError,
            // deleteFriendError,
            getFriendsError,
            acceptFriendRequestError,
            denyFriendRequestError,
            deleteFriendRequestError,
            addFriendLoading,
            setAddFriendError,
            setAddFriendLoading,
            // Friend request reminder state
            sendFriendRequestReminder,
            friendRequestReminderLoading,
            friendRequestReminderError,
            friendRequestReminderSuccess,
            deleteFriendRequestLoading,
            deleteFriendLoading,
            acceptFriendRequestLoading,
            denyFriendRequestLoading,
        ]
    )

    useEffect(() => {
        getFriendsOnLoad()
    }, [authenticatedUser, getFriendsOnLoad])

    return <FriendContext.Provider value={memoedValue}>{children}</FriendContext.Provider>
}

export function useFriend() {
    return useContext(FriendContext)
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}
