import React, { useEffect, useState, useLayoutEffect } from "react"
import { useSearchParams, useNavigate, Link, useLocation } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, TextArea, Img, CheesyBeanLogo } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import { useFriend } from "../context/FriendContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import { LoginComponent } from "./LoginPage"
import NamePage from "./NamePage"
import buttonDecoration from "../buttonDecoration"
import PopupNotification from "../components/PopupNotification"

function formatNumber(number) {
    return `(${number?.slice(0, 3)})${number?.slice(3, 6)}-${number?.slice(6)}`
}

function ProfilePage() {
    const [profile, setProfile] = useState("")
    const [profileLoading, setProfileLoading] = useState(true)
    const [usersAccount, setUsersAccount] = useState(false)
    const [friendStatus, setFriendStatus] = useState(false)
    const [acceptFriendRequestError, setAcceptFriendRequestError] = useState(false)
    const [friendRequestSent, setFriendRequestSent] = useState(false)
    const [friendRequestReceived, setFriendRequestReceived] = useState(false)
    const [loading, setLoading] = useState(false)
    const [popupNotifText, setPopupNotifText] = useState("")
    const [popupNotifColor, setPopupNotifColor] = useState("")
    const [canSendReminder, setCanSendReminder] = useState(false)
    const [updatingName, setUpdatingName] = useState(false)
    const [deletingAccount, setDeletingAccount] = useState(false)
    const [unsubscribingAccount, setUnsubscribingAccount] = useState(false)

    const location = useLocation()
    const profileId = location.pathname.split("/profile/")[1]
    const nav = useNavigate()

    const { authenticatedUser, authenticate, firstLogin, setAuthenticatedUser } = useAuth()
    // const { setFirstLogin, firstLogin, authenticatedUser } = useAuth()

    const {
        friends,
        addFriend,
        receivedFriendRequests,
        sentFriendRequests,
        acceptFriendRequest,
        setAddFriendLoading,
        sendFriendRequestReminder,
        friendRequestReminderLoading,
        friendRequestReminderError,
        friendRequestReminderSuccess,
    } = useFriend()

    useEffect(() => {
        const getProfile = async () => {
            let url = `/profile/${profileId}`
            if (authenticatedUser.number) {
                url = `/profile/${profileId}?user=${authenticatedUser.number}`
            }
            try {
                const {
                    data: [profileData],
                } = await axios.get(url)
                setProfile(profileData)
                setProfileLoading(false)
            } catch (error) {
                console.log(error)
                // setProfileLoading(false)
            }
        }
        getProfile()
    }, [authenticatedUser.number, authenticatedUser.name, profileId])

    async function acceptFriendRequestCall(e, friendsNumber) {
        e.stopPropagation()
        const acceptedFriendRequestResponse = await acceptFriendRequest(friendsNumber)
        return acceptedFriendRequestResponse
    }

    async function unsubscribe(e) {
        e.stopPropagation()
        setLoading(true)
        const url = `/unsubscribeAccount`
        try {
            const { data } = await axios.post(url, {
                withCredentials: true,
                data: { usersNumber: cleanNumber(authenticatedUser.number) },
            })

            if (data.success) {
                setAuthenticatedUser({ ...authenticatedUser, subscribed: false })
                document.getElementById("unsubscribe-account-window").style.display = "none"
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    async function subscribe(e) {
        e.stopPropagation()
        setLoading(true)
        const url = `/subscribeAccount`
        try {
            const { data } = await axios.post(url, {
                withCredentials: true,
                data: { usersNumber: cleanNumber(authenticatedUser.number) },
            })
            if (data.success) {
                setAuthenticatedUser({ ...authenticatedUser, subscribed: true })
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    async function deleteAccount() {
        const url = `/deleteAccount`
        try {
            const { data } = await axios.post(url, {
                withCredentials: true,
                data: { usersNumber: cleanNumber(authenticatedUser.number) },
            })
            if (data.success) {
                document.getElementById("delete-account-window").style.display = "none"
                setDeletingAccount(false)
                await authenticate()
                nav("/")
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    // Need to write a function that see if it's our account, friends, friendRequested, not friends, or friend request received
    useEffect(() => {
        // find if this is our account
        if (authenticatedUser.number === profile.number) {
            setUsersAccount(true)
        } else if (usersAccount) setUsersAccount(false)

        // find if this is a friend
        if (friends?.length) {
            const friend = friends.find((friend) => friend.friend === profile.number)
            if (friend) setFriendStatus(true)
            else if (friendStatus) setFriendStatus(false)
        }

        // find if we sent a friend request
        if (sentFriendRequests?.length) {
            const sentRequest = sentFriendRequests.find((request) => request.number === profile.number)
            if (sentRequest) {
                setFriendRequestSent(true)
                if (sentRequest.canSendReminder) {
                    setCanSendReminder(true)
                }
            } else if (friendRequestSent) setFriendRequestSent(false)
        }

        // find if we received a friend request
        if (receivedFriendRequests?.length) {
            const receivedRequest = receivedFriendRequests.find(
                (request) => request.number === profile.number
            )
            if (receivedRequest) setFriendRequestReceived(true)
            else if (friendRequestReceived) setFriendRequestReceived(false)
        }
        // setProfileLoading(false)
    }, [
        profile,
        friends,
        sentFriendRequests,
        receivedFriendRequests,
        profileId,
        authenticatedUser,
        friendRequestReceived,
        friendRequestSent,
        friendStatus,
        usersAccount,
    ])

    //

    async function sendFriendRequestReminderCall(e, friendsNumber) {
        e.stopPropagation()
        return sendFriendRequestReminder(friendsNumber)
    }

    console.log(authenticatedUser, "auth user")

    return (
        <Flex alignItems="center" flexDirection="column" padding=" 12px 20px" minHeight="100vh" width="330px">
            <CheesyBeanLogo />
            {profileLoading ? (
                <BeanSpinnerDontAsk />
            ) : (
                <>
                    <Flex mt="16px" mb="50px">
                        <Flex
                            borderRadius="50%"
                            backgroundColor={colors.white}
                            height="50px"
                            width="50px"
                            border={buttonDecoration.lightBorder}
                            alignItems="center"
                            justifyContent="center"
                            mr="30px"
                        >
                            <Text fontWeight="500" fontSize="18px">
                                {profile?.name
                                    ? profile.name.split(" ")[0][0] +
                                      (profile.name.split(" ")[1]?.[0] ? profile.name.split(" ")[1][0] : "")
                                    : "XX"}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column">
                            <Text m="0" fontWeight="600" fontSize="16px" mb="6px">
                                {profile.name ? profile.name : "Has not signed up"}
                            </Text>
                            <Text
                                m="0"
                                fontWeight="600"
                                fontSize="16px"
                                style={{
                                    ...(!authenticatedUser.name && {
                                        filter: "blur(4px)",
                                    }),
                                }}
                            >
                                {formatNumber(profile.number).slice(0, 9)}
                                <Span
                                    style={{
                                        ...(authenticatedUser.name &&
                                            !friendStatus &&
                                            !friendRequestReceived &&
                                            !usersAccount && {
                                                filter: "blur(4px)",
                                            }),
                                    }}
                                >
                                    {formatNumber(profile.number).slice(9)}
                                </Span>
                            </Text>
                        </Flex>
                    </Flex>
                    {authenticatedUser.name && usersAccount && updatingName && (
                        <NamePage
                            setPopupNotifColor={setPopupNotifColor}
                            setPopupNotifText={setPopupNotifText}
                            page="profile"
                            title="Update your name"
                            insideTitleRemoved={true}
                            setUpdatingName={setUpdatingName}
                        />
                    )}
                    {authenticatedUser.name &&
                        usersAccount &&
                        !updatingName &&
                        authenticatedUser.subscribedOnText && (
                            <DeleteButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setUpdatingName(true)
                                }}
                                fontWeight="600"
                                fontSize="16px"
                                color={colors.white}
                                bgc={colors.offerBlue}
                                mb="10px"
                                disabled={loading}
                            >
                                Update your name
                            </DeleteButton>
                        )}
                    {!updatingName && usersAccount && !authenticatedUser.subscribedOnText && (
                        <Flex
                            flexDirection="column"
                            width="330px"
                            alignItems="center"
                            mb="16px"
                            bg={colors.white}
                            borderRadius="8px"
                            border={buttonDecoration.lightBorder}
                            padding="12px 8px"
                            position="relative"
                        >
                            <Text fontWeight="600" fontSize="14px" textAlign="center" m="0">
                                You still need to text "Y" to our number to finish sign up! 💬
                            </Text>
                            <DeleteButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    Object.assign(document.createElement("a"), {
                                        href: `sms:+1${2543454978}?&body=${`Y`}`,
                                    }).click()
                                }}
                                fontWeight="600"
                                fontSize="18px"
                                color={colors.white}
                                bgc={colors.offerGreen}
                                disabled={loading}
                                mb="12px"
                            >
                                Click to text "Y"
                            </DeleteButton>
                            <Text
                                textAlign="center"
                                m="0"
                                fontWeight="400"
                                color={colors.gray}
                                fontSize="12px"
                            >
                                This is INTEGRAL to using CheesyBean. This will allow you to receive friend
                                request notifications, Bean time announcements, & reminders!
                            </Text>
                            <Span
                                position="absolute"
                                top="-18px"
                                right="-18px"
                                borderRadius="50%"
                                border={buttonDecoration.lightBorder}
                                boxShadow={buttonDecoration.veryVeryLightBoxshadow}
                                height="32px"
                                width="32px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="20px"
                                backgroundColor={colors.offerGreen}
                            >
                                1
                            </Span>
                        </Flex>
                    )}
                    {authenticatedUser.name &&
                        usersAccount &&
                        !updatingName &&
                        !authenticatedUser.subscribedOnText && (
                            <DeleteButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setUpdatingName(true)
                                }}
                                fontWeight="600"
                                fontSize="16px"
                                color={colors.white}
                                bgc={colors.offerBlue}
                                mb="30px"
                                disabled={loading}
                            >
                                Update your name
                            </DeleteButton>
                        )}
                    {!updatingName &&
                        usersAccount &&
                        authenticatedUser.subscribed &&
                        authenticatedUser.subscribedOnText && (
                            <Flex
                                flexDirection="column"
                                maxWidth="300px"
                                alignItems="center"
                                // mb="20px"
                                // bg={colors.white}
                                // borderRadius="8px"
                                // border={buttonDecoration.lightBorder}
                                // padding="12px 8px"
                            >
                                {/* <Text fontWeight="600" fontSize="14px" textAlign="center" m="0">
                                    This will unsubscribe your number from texts from us! 😥
                                </Text> */}
                                <DeleteButton
                                    // onClick={unsubscribe}
                                    onClick={async (e) => {
                                        e.stopPropagation()
                                        document.getElementById("unsubscribe-account-window").style.display =
                                            "flex"
                                        setDeletingAccount(true)
                                    }}
                                    fontWeight="600"
                                    fontSize="12px"
                                    color={colors.white}
                                    bgc={colors.offOrange}
                                    disabled={loading}
                                    mb="8px"
                                    bs={buttonDecoration.veryVeryLightBoxshadow}
                                >
                                    Unsubscribe
                                </DeleteButton>
                                {/* <Text
                                    textAlign="center"
                                    m="0"
                                    fontWeight="400"
                                    color={colors.gray}
                                    fontSize="12px"
                                >
                                    You will no longer receive friend request notifications, Bean time
                                    announcements, & Bean reminders.
                                </Text> */}
                            </Flex>
                        )}
                    {!updatingName &&
                        usersAccount &&
                        !authenticatedUser.subscribed &&
                        authenticatedUser.subscribedOnText && (
                            <Flex
                                flexDirection="column"
                                maxWidth="300px"
                                alignItems="center"
                                mb="20px"
                                bg={colors.white}
                                borderRadius="8px"
                                border={buttonDecoration.lightBorder}
                                padding="12px 8px"
                            >
                                <Text textAlign="center" m="0" fontWeight="600" fontSize="14px">
                                    This will subscribe your number to texts from us! 🥳
                                </Text>
                                <DeleteButton
                                    onClick={subscribe}
                                    fontWeight="600"
                                    fontSize="16px"
                                    color={colors.white}
                                    bgc={colors.offerGreen}
                                    disabled={loading}
                                    mb="8px"
                                >
                                    Subscribe
                                </DeleteButton>
                                <Text
                                    textAlign="center"
                                    m="0"
                                    fontWeight="400"
                                    color={colors.gray}
                                    fontSize="12px"
                                >
                                    You will receive friend request notifications, Bean time announcements, &
                                    Bean reminders.
                                </Text>
                            </Flex>
                        )}
                    {/* {!updatingName &&
                        usersAccount &&
                        !authenticatedUser.subscribed &&
                        !authenticatedUser.subscribedOnText && (
                            <Flex flexDirection="column" maxWidth="300px" alignItems="center">
                                <DeleteButton
                                    onClick={subscribe}
                                    fontWeight="600"
                                    fontSize="16px"
                                    color={colors.white}
                                    bgc={colors.offerGreen}
                                    disabled={loading}
                                    mb="8px"
                                >
                                    Subscribe
                                </DeleteButton>
                                <Text
                                    textAlign="center"
                                    m="0"
                                    fontWeight="400"
                                    color={colors.gray}
                                    fontSize="12px"
                                >
                                    {" "}
                                    {!authenticatedUser.subscribedOnText &&
                                        `You will receive a text that you will need to respond "Y" to! `}
                                    This will subscribe your number to text reminders & the bean time
                                    announcement! 🥳
                                </Text>
                            </Flex>
                        )} */}
                    {!updatingName && usersAccount && (
                        <Flex flexDirection="column" maxWidth="300px" alignItems="center" mb="50px">
                            <DeleteButton
                                onClick={async (e) => {
                                    e.stopPropagation()
                                    document.getElementById("delete-account-window").style.display = "flex"
                                    setDeletingAccount(true)
                                }}
                                fontWeight="600"
                                fontSize="12px"
                                color={colors.white}
                                bgc={colors.offRed}
                                disabled={loading}
                                mb="12px"
                                bs={buttonDecoration.veryVeryLightBoxshadow}
                            >
                                Delete account
                            </DeleteButton>
                            {/* <Text
                                textAlign="center"
                                m="0"
                                fontWeight="400"
                                color={colors.gray}
                                fontSize="12px"
                            >
                                This will delete all friends, friend requests,
                                account, and unsubscribe your number from any
                                messages
                            </Text> */}
                        </Flex>
                    )}
                    {!authenticatedUser.number && (
                        <LoginComponent
                            title={`Login to add ${profile?.name?.split(" ")[0]} as a
                                friend!`}
                        />
                    )}
                    {authenticatedUser.number && !authenticatedUser.name && (
                        <NamePage
                            title={`Signup to add ${profile?.name?.split(" ")[0]} as
                                a friend!`}
                            setPopupNotifColor={setPopupNotifColor}
                            setPopupNotifText={setPopupNotifText}
                            page="profile"
                        />
                    )}
                    {authenticatedUser.number && authenticatedUser.name && (
                        <Container>
                            <Flex flexDirection="column" alignItems="center">
                                {!usersAccount && (
                                    <DeleteButton
                                        bgc={colors.offGreen}
                                        disabled={loading || friendRequestSent}
                                        onClick={async (e) => {
                                            e.stopPropagation()
                                            setLoading(true)
                                            if (
                                                !friendStatus &&
                                                !friendRequestSent &&
                                                !friendRequestReceived
                                            ) {
                                                setAddFriendLoading(true)
                                                // Add friend
                                                const response = await addFriend(() => {}, profile.number)
                                                if (response) {
                                                    setFriendRequestSent(true)
                                                    makePopupNotif(
                                                        setPopupNotifColor,
                                                        setPopupNotifText,
                                                        colors.offGreen,
                                                        "Friend request sent!",
                                                        5000,
                                                        "popup-notif"
                                                    )
                                                } else {
                                                    makePopupNotif(
                                                        setPopupNotifColor,
                                                        setPopupNotifText,
                                                        colors.offRed,
                                                        "Friend request failed to send!",
                                                        5000,
                                                        "popup-notif"
                                                    )
                                                }
                                            } else if (friendRequestReceived) {
                                                // Accept friend request
                                                const response = await acceptFriendRequestCall(
                                                    e,
                                                    profile.number
                                                )

                                                if (response) {
                                                    setFriendRequestReceived(false)
                                                    setFriendStatus(true)
                                                    makePopupNotif(
                                                        setPopupNotifColor,
                                                        setPopupNotifText,
                                                        colors.offGreen,
                                                        "Friend request accepted!",
                                                        5000,
                                                        "popup-notif"
                                                    )
                                                } else {
                                                    makePopupNotif(
                                                        setPopupNotifColor,
                                                        setPopupNotifText,
                                                        colors.offRed,
                                                        "Friend request acceptance failed!",
                                                        5000,
                                                        "popup-notif"
                                                    )
                                                }
                                            }
                                            setLoading(false)
                                        }}
                                        color={colors.white}
                                        fontWeight="600"
                                        fontSize="20px"
                                        bs={friendStatus ? "none" : ""}
                                    >
                                        <Text m="0">
                                            {friendStatus && "Friends"}
                                            {friendRequestSent && "Friend request sent"}
                                            {friendRequestReceived && "Accept friend request"}
                                            {!friendStatus &&
                                                !friendRequestSent &&
                                                !friendRequestReceived &&
                                                "Add friend"}
                                        </Text>
                                    </DeleteButton>
                                )}
                                {friendRequestSent && (
                                    <DeleteButton
                                        bgc={colors.brightPurple}
                                        disabled={
                                            loading ||
                                            !canSendReminder ||
                                            friendRequestReminderSuccess === profile.number
                                        }
                                        onClick={async (e) => {
                                            setLoading(true)
                                            const resp = await sendFriendRequestReminderCall(
                                                e,
                                                profile.number
                                            )
                                            if (resp) {
                                                makePopupNotif(
                                                    setPopupNotifColor,
                                                    setPopupNotifText,
                                                    colors.offerGreen,
                                                    "Reminder sent!",
                                                    5000,
                                                    "popup-notif"
                                                )
                                            } else {
                                                makePopupNotif(
                                                    setPopupNotifColor,
                                                    setPopupNotifText,
                                                    colors.errorRed,
                                                    "Failed to send reminder.",
                                                    5000,
                                                    "popup-notif"
                                                )
                                            }
                                            setLoading(false)
                                        }}
                                        color={colors.white}
                                        fontWeight="600"
                                        fontSize="20px"
                                        bs={friendStatus ? "none" : ""}
                                    >
                                        <Img src="whitePointer.svg" height="26px" width="26px" mr="8px" />
                                        <Text m="0">Poke</Text>
                                    </DeleteButton>
                                )}
                            </Flex>
                            {!updatingName && (
                                <DeleteButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        nav(`/home`)
                                    }}
                                    fontWeight="600"
                                    fontSize="20px"
                                    color={colors.black}
                                    bgc={colors.white}
                                    mb="80px"
                                >
                                    {firstLogin ? "Next" : "Go home"}
                                </DeleteButton>
                            )}
                        </Container>
                    )}
                </>
            )}
            <StyledConfirmationWindow
                className="delete-account-window"
                id="delete-account-window"
                onClick={(e) => {
                    if (e.target.classList.contains("delete-account-window")) {
                        document.getElementById("delete-account-window").style.display = "none"
                        setDeletingAccount(false)
                    }
                }}
            >
                <ExplainerContainer bgc={colors.white}>
                    <Text fontWeight="600" fontSize="16px" textAlign="center" m="0">
                        Would you like to delete your account?{" "}
                    </Text>
                    <Flex width="70%" justifyContent="center">
                        <DeleteButton
                            color="white"
                            bgc={colors.black}
                            mr="30px"
                            padding="8px 16px"
                            onClick={async () => deleteAccount()}
                        >
                            <Text m="0" fontWeight="600">
                                Yes
                            </Text>
                        </DeleteButton>
                        <DeleteButton
                            color="white"
                            bgc={colors.offerGreen}
                            onClick={() => {
                                document.getElementById("delete-account-window").style.display = "none"
                                setDeletingAccount(false)
                            }}
                        >
                            <Text m="0" fontWeight="600">
                                No
                            </Text>
                        </DeleteButton>
                    </Flex>
                    <Text textAlign="center" m="0" fontWeight="400" fontSize="12px">
                        This will delete any data associated with the account
                    </Text>
                </ExplainerContainer>
            </StyledConfirmationWindow>
            <StyledConfirmationWindow
                className="unsubscribe-account-window"
                id="unsubscribe-account-window"
                onClick={(e) => {
                    if (e.target.classList.contains("unsubscribe-account-window")) {
                        document.getElementById("unsubscribe-account-window").style.display = "none"
                        setDeletingAccount(false)
                    }
                }}
            >
                <ExplainerContainer bgc={colors.white}>
                    <Text fontWeight="600" fontSize="16px" textAlign="center" m="0">
                        Would you like to unsubscribe your account from texts from us! 😥
                    </Text>
                    <Flex width="70%" justifyContent="center">
                        <DeleteButton
                            onClick={unsubscribe}
                            fontWeight="600"
                            fontSize="12px"
                            mr="30px"
                            bgc={colors.black}
                            disabled={loading}
                            bs={buttonDecoration.veryVeryLightBoxshadow}
                        >
                            <Text m="0" fontWeight="600" color={colors.white}>
                                Yes
                            </Text>
                        </DeleteButton>
                        <DeleteButton
                            color="white"
                            bgc={colors.offerGreen}
                            onClick={() => {
                                document.getElementById("unsubscribe-account-window").style.display = "none"
                                setDeletingAccount(false)
                            }}
                        >
                            <Text m="0" fontWeight="600">
                                No
                            </Text>
                        </DeleteButton>
                    </Flex>
                    <Text textAlign="center" m="0" fontWeight="400" fontSize="12px">
                        You will no longer receive friend request notifications, Bean time announcements, &
                        Bean reminders.
                    </Text>
                </ExplainerContainer>
            </StyledConfirmationWindow>
            <PopupNotification bg={popupNotifColor} text={popupNotifText} id={"popup-notif"} />
        </Flex>
    )
}

export default ProfilePage

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text, time, id) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById(id).style.display = "flex"
    setTimeout(() => {
        let notif = document.getElementById(id)
        if (notif) notif.style.display = "none"
    }, time)
}

const Container = styled(Flex)`
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`

const DeleteButton = styled(Button)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    margin-top: 12px;
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: ${({ padding }) => (padding ? padding : "8px 16px")};
    transition: all 0.3s ease;
    margin-bottom: ${({ mb }) => (mb ? mb : "20px")};
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;

    ${({ bs }) =>
        !bs &&
        `&:hover {
        cursor: pointer;
        box-shadow: ${buttonDecoration.lightBoxshadow};
        border: ${({ borderColor }) =>
            borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
    }`}
`

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2).match(/\d/g).join("")
    } else {
        usersNumber = number
    }
    return usersNumber
}

const StyledConfirmationWindow = styled(Flex)`
    position: fixed;
    z-index: 3;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(4px);
    display: none;
`
const ExplainerContainer = styled(Flex)`
    flex-direction: column;
    width: 320px;
    overflow: scroll;
    align-items: center;
    background-color: ${colors.white};
    padding: 20px 20px 20px 20px;
    background-color: ${({ bgc }) => (bgc ? bgc : colors.offRed)};
    box-shadow: ${buttonDecoration.mediumBoxshadow};
    border: ${buttonDecoration.mediumBorder};
    border-radius: 8px;
`
