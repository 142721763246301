import styled from "styled-components"
import {
    color,
    layout,
    typography,
    flexbox,
    border,
    background,
    position,
    shadow,
    grid,
    space,
} from "styled-system"

const Input = styled.input`
    ${color}
    ${layout}
    ${typography}
    ${flexbox}
    ${border}
    ${background}
    ${position}
    ${shadow}
    ${grid}
    ${space}
    box-sizing: border-box;
    margin: 0;
`

export default Input
