import React, { useEffect } from "react"
import { HashRouter, Routes, Route, Outlet } from "react-router-dom"
import LoginPage from "./pages/LoginPage"
import AuthProvider from "./context/AuthContext"
import BeanProvider from "./context/BeanContext"
import FriendProvider from "./context/FriendContext"
import "./index.css"
import Dashboard from "./pages/Dashboard"
import SharedBeanPage from "./pages/SharedBeanPage"
import ProfilePage from "./pages/ProfilePage"
import PrivacyPage from "./pages/PrivacyPage"
import TermsOfService from "./pages/TermsOfService"
import { QuestionMarkExplainer } from "./components"
import ContactPage from "./pages/ContactPage"

function App() {
    return (
        <HashRouter>
            <AuthProvider>
                <FriendProvider>
                    <BeanProvider>
                        <div id="website-container">
                            {/* <div className="top-banner">
                                <p className="top-banner-text">App coming soon! 🥳</p>
                            </div> */}
                            <Routes>
                                <Route exact path="/" element={<ContactPage />} />
                                <Route exact path="/contact" element={<ContactPage />} />
                                {/* <Route path="/home" element={<Dashboard />} /> */}
                                {/* <Route path="/bean/:id" element={<SharedBeanPage />} />
                                <Route path="/profile/:id" element={<ProfilePage />} /> */}
                                <Route path="/privacy-policy/" element={<PrivacyPage />} />
                                <Route path="/terms-of-service/" element={<TermsOfService />} />
                                <Route path="*" element={<LoginPage />} />
                            </Routes>
                            {/* <QuestionMarkExplainer /> */}
                        </div>
                    </BeanProvider>
                </FriendProvider>
            </AuthProvider>
        </HashRouter>
    )
}

export default App
