import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link, useLocation } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, TextArea, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import { useBean } from "../context/BeanContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import { LoginComponent } from "./LoginPage"
import NamePage from "./NamePage"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function SharedBeanPage() {
    const [bean, setBean] = useState("")
    const [beanLoading, setBeanLoading] = useState(true)
    const [beanStatus, setBeanStatus] = useState("")
    const [friendStatus, setFriendStatus] = useState("")
    const [acceptFriendRequestError, setAcceptFriendRequestError] = useState("")
    const [beanOwner, setBeanOwner] = useState("")
    const [beanOwnerName, setBeanOwnerName] = useState("")

    const location = useLocation()
    const beanId = location.pathname.split("/bean/")[1]
    const nav = useNavigate()

    const { authenticatedUser } = useAuth()
    const {
        loading,
        usersBean,
        friendsBeans,
        usersRealBean,
        usersCurrentBean,
        nextBeanDate,
        setUsersBean,
        setUsersRealBean,
    } = useBean()

    useEffect(() => {
        const getBean = async () => {
            let url = `/beans/${beanId}`
            if (authenticatedUser.number) {
                url = `/beans/${beanId}?user=${authenticatedUser.number}`
            }
            try {
                const { data } = await axios.get(url)
                setBean(data.bean)
                setBeanStatus(data.beanStatus)
                setBeanLoading(false)
                if (data.owner) {
                    setBeanOwner(data.owner)
                    setBeanOwnerName(data.name)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getBean()
    }, [authenticatedUser.number, authenticatedUser.name, friendStatus])

    function acceptFriendRequest(e) {
        e.stopPropagation()
        async function acceptFriend() {
            try {
                const { data } = await axios.post(`/acceptFriendRequest`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendsNumber: beanOwner,
                    },
                })
                if (data.error) {
                    setAcceptFriendRequestError("Failed to accept friend")
                } else {
                    setFriendStatus("friends")
                    // setFriends(data.friends)
                    // setReceivedFriendRequests(data.friendRequestsReceived)
                }
            } catch (error) {
                setAcceptFriendRequestError("Failed to accept friend")
                console.log(error, "ERROR")
            }
        }

        acceptFriend()
    }

    return (
        <Flex alignItems="center" flexDirection="column" padding="20px">
            <CheesyBeanLogo
                onClick={(e) => {
                    e.stopPropagation()
                    nav("/home")
                }}
            >
                <Text fontWeight="700" mr="6px" mt="0" mb="0" textAlign="bottom">
                    CheesyBean
                </Text>
            </CheesyBeanLogo>
            {loading || beanLoading ? (
                <BeanSpinnerDontAsk />
            ) : (
                <>
                    <Flex
                        flexDirection="column"
                        padding="10px 10px"
                        className="fade-in-long"
                        backgroundColor={colors.buttonBackground}
                        width="300px"
                        margin="0 10px 20px 10px"
                        height={beanStatus === "full bean" ? "400px" : "100px"}
                        boxShadow="inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
                        inset -2px -2px #808080, inset 2px 2px #dfdfdf"
                    >
                        <Text m="0" color={colors.wordsChosen} fontWeight={500}>
                            {beanOwnerName}'s bean!
                        </Text>
                        <StyledTextArea
                            m="0"
                            color={colors.wordsChosen}
                            backgroundColor={colors.cheesyBeanYellow}
                            height="100%"
                            padding="10px"
                            maxHeight="100%"
                            value={bean}
                            onChange={() => {}}
                        ></StyledTextArea>
                    </Flex>
                    {!authenticatedUser.number && (
                        <>
                            <Text m="0 0 10px 0" color={colors.wordsChosen} fontWeight={500}>
                                Login{"/"}Sign up to view {beanOwnerName}'s whole bean!
                            </Text>
                            <LoginComponent />
                        </>
                    )}
                    {authenticatedUser.number && !authenticatedUser.name && <NamePage />}
                    {authenticatedUser.number && authenticatedUser.name && beanStatus === "partial bean" && (
                        <ItemExpand open={true}>
                            <Text>Do you accept {beanOwnerName}'s friend request?</Text>
                            <DeleteButton
                                onClick={(e) => acceptFriendRequest(e)}
                                color={colors.lightGreen}
                                fontWeight="600"
                                fontSize="20px"
                            >
                                Yes
                            </DeleteButton>
                            <DeleteButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    nav(`/home`)
                                }}
                                fontWeight="500"
                                fontSize="16px"
                                color={colors.wordsNotChosen}
                            >
                                Not right now, take me home
                            </DeleteButton>
                        </ItemExpand>
                    )}
                    {beanStatus === "full bean" && (
                        <DeleteButton
                            onClick={(e) => {
                                e.stopPropagation()
                                nav(`/home`)
                            }}
                            fontWeight="600"
                            fontSize="20px"
                            color={colors.lightGreen}
                        >
                            Go home
                        </DeleteButton>
                    )}
                </>
            )}
        </Flex>
    )
}

export default SharedBeanPage

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) => (opened ? colors.buttonBackgroundChosen : colors.buttonBackground)};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) => (bg ? bg : colors.buttonBackgroundChosen)};
    }
`

const CheesyBeanLogo = styled(Flex)`
    border-radius: 25px;
    background-color: ${colors.cheesyBeanYellow};
    padding: 10px;
    alignitems: center;
    margin-top: 10px;
    margin-bottom: 25px;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${buttonDecoration.mediumBorder};

    &:hover {
        cursor: pointer;
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
    resize: none;
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    margin-top: 16px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.buttonBackgroundChosen};
    }
`
