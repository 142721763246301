import React, { useState } from "react"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"

function QuestionMarkExplainer() {
    const [showExplanation, setShowExplanation] = useState(false)

    return (
        <>
            <StyledConfirmationWindow
                className="explainer-window"
                id="explainer-window"
                onClick={(e) => {
                    if (e.target.classList.contains("explainer-window")) {
                        document.getElementById("explainer-window").style.display = "none"
                        setShowExplanation(false)
                    }
                }}
            >
                {showExplanation && (
                    <ExplainerContainer>
                        <Text fontWeight="600" fontSize="22px" textAlign="left" mt="14px" mb="8px">
                            Welcome to CheesyBean, a place where beans come true! 🎊
                        </Text>
                        {/* <WelcomeImg src="welcome.svg" /> */}

                        <Text fontWeight="600" fontSize="14px">
                            Our goal is to help you stay in touch with the closest people in your life. We do
                            this through providing a no-frills phone-based platform where your friends and
                            loved ones can share major life updates, casual stories, and general thoughts and
                            feelings.
                        </Text>
                        {/* <Text>The 3 cheesy steps:</Text> */}
                        <Flex width="100%" mt="10px" alignItems="center">
                            <Img src="numberOne.svg" height="40px" width="40px" mr="17px" />
                            <Text fontWeight="600">
                                Write your Bean <Span fontSize="26px">📝</Span>
                            </Text>
                        </Flex>
                        <Text fontSize="14px">
                            Every other Sunday at 10pm EST all Beans are due. “Bean” is our shorthand for
                            written life updates. Think: a text to an old friend, a blogpost, some thoughts
                            that have been preoccupying your mind, or a new comedy routine you’re working on.
                            What your Bean looks like is totally up to you!
                            {/* Make sure to get
                            your Bean in on time, because the next set of Beans
                            won’t go out for 2 more weeks. */}
                        </Text>
                        {/* <Text fontSize="12px" fontWeight="300" color={colors.gray}>
                            Don't worry, you’ll receive text reminders beforehand.
                        </Text> */}
                        <Flex width="100%" mt="10px" alignItems="center">
                            <Img src="numberTwo.svg" height="40px" width="40px" mr="17px" />
                            <Text fontWeight="600">
                                Check your friends’ Beans <Span fontSize="26px">😁</Span>
                            </Text>
                        </Flex>
                        <Text fontSize="14px">
                            Once Bean Time has passed, you’ll receive a text announcing the arrival of all
                            your friends’ Beans. From there, you can login and read about what everyone’s been
                            up to in the past two weeks.
                            {/* If
                anything a friend writes catches your eye, you can call or text
                them to catch up. 
                What better time to reach out and keep the
                conversation going? */}
                        </Text>
                        {/* <Text fontSize="12px" fontWeight="300" color={colors.gray}>
                            This makes every other Sunday an occasion to look forward to!
                        </Text> */}
                        <Flex width="100%" mt="10px" alignItems="center">
                            <Img src="numberThree.svg" height="40px" width="40px" mr="17px" />
                            <Text fontWeight="600">
                                Connect with those closest to you <Span fontSize="26px">🤟</Span>
                            </Text>
                        </Flex>
                        <Text fontSize="14px">
                            Add whoever you hope to keep in touch with! Remember, Beans are only readable by
                            friends you’ve accepted. This is to make sure your Bean is only seen by the people
                            you wrote it for. Even if you shared the link, users who you are not friends with
                            can't read your Bean.
                        </Text>
                        <Text fontWeight="600" fontSize="14px" mt="18px">
                            CheesyBean isn’t a competition or a performance, it’s a platform for genuine
                            connection. We recommend adding people you can be honest with :)
                        </Text>
                        <Flex width="100%" mt="24px" alignItems="center">
                            <Img src="supportText.png" height="46px" width="46px" mr="17px" />
                            {/* <Text fontSize="40px" m="0 10px 0 0 ">
                                💬
                            </Text> */}
                            <Text fontWeight="600">
                                Support{" "}
                                <StyledSpan
                                    lineHeight="40px"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        Object.assign(document.createElement("a"), {
                                            href: `sms:+1${2543454978}?&body=${`Hi! I have a question about CheesyBean 🧐. `}`,
                                        }).click()
                                    }}
                                    bg={colors.offerBlue}
                                    color="white"
                                    fontWeight="600"
                                    fontSize="18px"
                                    style={{ display: "inline" }}
                                >
                                    text us!
                                </StyledSpan>
                            </Text>
                        </Flex>
                        <Text fontSize="14px" mb="40px">
                            If you have feature requests, questions, or feedback, please text us! We love
                            hearing from our amazing users.
                        </Text>
                    </ExplainerContainer>
                )}
            </StyledConfirmationWindow>
            <QuestionMarkExplainerStyle
                onClick={() => {
                    document.getElementById("explainer-window").style.display = "flex"
                    setShowExplanation(true)
                }}
            >
                <Flex bg={colors.offerBlue} borderRadius="50%">
                    <Img src="questionMark.png" height="36px" width="36px" />
                </Flex>
            </QuestionMarkExplainerStyle>
        </>
    )
}

const QuestionMarkExplainerStyle = styled(Flex)`
    position: fixed;
    bottom: 12px;
    right: 12px;
`

const StyledConfirmationWindow = styled(Flex)`
    position: fixed;
    z-index: 3;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(4px);
    display: none;
`
const ExplainerContainer = styled(Flex)`
    flex-direction: column;
    width: 320px;
    height: 70vh;
    overflow: scroll;
    background-color: ${colors.white};
    padding: 0 20px 20px 20px;
    background-color: white;
    box-shadow: ${buttonDecoration.mediumBoxshadow};
    border: ${buttonDecoration.mediumBorder};
    border-radius: 8px;
`

const StyledSpan = styled(Span)`
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.veryLightBoxshadow)};
    ${({ bgc }) => (bgc ? `background-color: ${bgc}` : "")};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : buttonDecoration.lightBorder)};
    border-radius: 6px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    transition: all 0.3s ease;
    ${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
    width: fit-content;
    display: flex;
    align-items: center;
    margin-right: 10px;

    ${({ bs }) =>
        !bs &&
        `&:hover {
    cursor: pointer;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${({ borderColor }) =>
        borderColor ? `2px solid ${borderColor}` : buttonDecoration.mediumBorder};
}`};
`

export default QuestionMarkExplainer
