import React, { useEffect, useState, useLayoutEffect } from "react"
import { Flex, Text, Input, Label, Span, Box, Button, Img, CountDown } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import { useAuth } from "../context/AuthContext"
import BeanPage from "./BeanPage"
import FriendPage from "./FriendPage"
import NamePage from "./NamePage"
import ExplanationPage from "./ExplanationPage"
import { useNavigate } from "react-router-dom"
import PopupNotification from "../components/PopupNotification"
import TextSubscribeNotif from "../components/TextSubscribeNotif"
import QRCode from "qrcode"

function Dashboard() {
    const { authenticatedUser, firstLogin, textSubscribeNotif } = useAuth()

    const [tab, setTab] = useState("friends")
    const [popupNotifText, setPopupNotifText] = useState(colors.offerGreen)
    const [popupNotifColor, setPopupNotifColor] = useState("copied!")
    const [openedItem, setOpenedItem] = useState("")

    // const firstLogin = true
    const nav = useNavigate()

    return (
        <Flex
            flexDirection="column"
            width="330px"
            alignItems="center"
            position="relative"
            minHeight="calc(100vh - 40px)"
            justifyContent="space-between"
        >
            <Flex flexDirection="column" width="100%" alignItems="center">
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    mb="12px"
                    mt="12px"
                    flexDirection="column"
                    position="relative"
                    width="100%"
                >
                    <CountDown />
                    <CheesyBeanLogo
                        onClick={(e) => {
                            e.stopPropagation()
                            navigator.clipboard.writeText(`https://cheesy-bean.com/#/`)
                            if (authenticatedUser.name && !firstLogin) {
                                if (tab === "beans") {
                                    setTab("friends")
                                } else if (tab !== "friends") {
                                    nav("/home")
                                }
                            }
                            makePopupNotif(
                                setPopupNotifColor,
                                setPopupNotifText,
                                colors.offerGreen,
                                "copied CheesyBean's url!",
                                3000,
                                "popup-notif-dashboard"
                            )
                        }}
                    >
                        <Text fontWeight="700" mr="0" mt="0" mb="0" textAlign="bottom">
                            CheesyBean
                        </Text>
                    </CheesyBeanLogo>
                    {/* Once we want to have the profile page we can uncomment this */}
                    {authenticatedUser.name && !firstLogin && (
                        // <StyledImg
                        //     src="profileIcon.png"
                        //     height="40px"
                        //     width="40px"
                        //     position="absolute"
                        //     top="10px"
                        //     right="10px"
                        //     bg="white"
                        //     padding="5px"
                        //     borderRadius="8px"
                        //     boxShadow={buttonDecoration.veryLightBoxshadow}
                        //     border={buttonDecoration.lightBorder}
                        //     onClick={() => {
                        //         nav("/profile/" + authenticatedUser.id)
                        //     }}
                        // />
                        <Flex
                            borderRadius="50%"
                            backgroundColor={colors.white}
                            height="36px"
                            width="36px"
                            alignItems="center"
                            justifyContent="center"
                            position="absolute"
                            top="10px"
                            right="0px"
                            bg="white"
                            padding="5px"
                            boxShadow={buttonDecoration.veryLightBoxshadow}
                            border={buttonDecoration.lightBorder}
                            onClick={() => {
                                nav("/profile/" + authenticatedUser.id)
                            }}
                        >
                            <Text fontWeight="500" fontSize="18px">
                                {authenticatedUser?.name
                                    ? authenticatedUser.name.split(" ")[0][0] +
                                      (authenticatedUser.name.split(" ")[1]?.[0]
                                          ? authenticatedUser.name.split(" ")[1][0]
                                          : "")
                                    : "XX"}
                            </Text>
                            {!authenticatedUser.subscribedOnText && (
                                <Span
                                    position="absolute"
                                    top="-10px"
                                    right="-12px"
                                    borderRadius="50%"
                                    border={buttonDecoration.lightBorder}
                                    boxShadow={buttonDecoration.veryVeryLightBoxshadow}
                                    height="24px"
                                    width="24px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize="16px"
                                    backgroundColor={colors.offerGreen}
                                >
                                    1
                                </Span>
                            )}
                        </Flex>
                    )}
                    {authenticatedUser.name && !firstLogin && (
                        <NetSlider
                            onClick={(e) => {
                                e.stopPropagation()
                                if (tab === "friends") {
                                    // Turning off jackson noise
                                    // let audio = new Audio("theBeansAreOutJackson.m4a")
                                    // audio.play()
                                    setTab("beans")
                                } else {
                                    setTab("friends")
                                }
                            }}
                        >
                            <ChartSliderWord chosen={tab === "friends"}>Friends</ChartSliderWord>
                            <ChartSliderButton tab={tab} />
                            <ChartSliderWord chosen={tab === "beans"}>Beans</ChartSliderWord>
                        </NetSlider>
                    )}
                </Flex>
                {!authenticatedUser.name && (
                    <NamePage
                        setPopupNotifText={setPopupNotifText}
                        setPopupNotifColor={setPopupNotifColor}
                        title="Sign up!"
                    />
                )}
                {authenticatedUser.name && firstLogin && <ExplanationPage />}
                {tab === "friends" && authenticatedUser.name && !firstLogin && <FriendPage />}
                {tab === "beans" && authenticatedUser.name && !firstLogin && <BeanPage />}
            </Flex>
            {authenticatedUser.name && !firstLogin && (
                <ItemExpand
                    bg={colors.offGreen}
                    opened={"invitePeople" === openedItem}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (openedItem !== "invitePeople") {
                            setOpenedItem("invitePeople")
                        } else {
                            setOpenedItem("")
                        }
                    }}
                    open={"invitePeople" === openedItem}
                    openHeight={"140px"}
                    closeHeight={"64px"}
                    bs={"invitePeople" === openedItem && buttonDecoration.heavyBoxshadow}
                    border={"invitePeople" === openedItem && buttonDecoration.mediumBorder}
                    hover={true}
                    m="0 0 20px 0"
                >
                    <Flex justifyContent="space-between">
                        {"invitePeople" !== openedItem ? (
                            <>
                                <Text m="0" fontWeight="600" alignSelf="center" color={colors.white}>
                                    Share CheesyBean!
                                </Text>
                                {/* <IconImg
                                src="paperAndPencil.png"
                                height="46px"
                                width="46px"
                                border={buttonDecoration.mediumBorder}
                                p="4px"
                                bg={colors.offerGreen}
                                boxShadow={buttonDecoration.veryLightBoxshadow}
                                borderRadius="6px"
                            /> */}
                                <IconImg
                                    src="thumbsUp.png"
                                    height="38px"
                                    width="38px"
                                    border={buttonDecoration.lightBorder}
                                    p="8px"
                                    bg={colors.offerGreen}
                                    boxShadow={buttonDecoration.veryLightBoxshadow}
                                    borderRadius="6px"
                                />
                            </>
                        ) : (
                            <Flex
                                flexDirection="column"
                                className="slide-in-from-above"
                                width="100%"
                                justifyContent="center"
                            >
                                <Text m="0" fontWeight="600" alignSelf="center" color={colors.white}>
                                    Share CheesyBean by...
                                </Text>
                                <Flex justifyContent="space-between" mt="8px" width="90%" alignSelf="center">
                                    <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                        <IconImg
                                            src="chatBubble.png"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                Object.assign(document.createElement("a"), {
                                                    href: `sms://?&body=Here's CheesyBean,  It's like BeReal, but way more real 🤫 https://cheesy-bean.com/#/`,
                                                }).click()
                                                makePopupNotif(
                                                    setPopupNotifColor,
                                                    setPopupNotifText,
                                                    colors.offerGreen,
                                                    "texts opened!",
                                                    3000,
                                                    "popup-notif-dashboard"
                                                )
                                            }}
                                            height="46px"
                                            width="46px"
                                            border={buttonDecoration.mediumBorder}
                                            p="4px"
                                            bg={colors.offerBlue}
                                            boxShadow={buttonDecoration.veryLightBoxshadow}
                                            borderRadius="6px"
                                        />
                                        <Text
                                            fontSize="10px"
                                            m="2px 0 0 0"
                                            textAlign="center"
                                            color={colors.white}
                                        >
                                            (Texting url)
                                        </Text>
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                        <IconImg
                                            src="qrCode.png"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                document.getElementById("help-window-cheesy").style.display =
                                                    "flex"
                                            }}
                                            height="46px"
                                            width="46px"
                                            border={buttonDecoration.mediumBorder}
                                            p="4px"
                                            bg={colors.white}
                                            boxShadow={buttonDecoration.veryLightBoxshadow}
                                            borderRadius="6px"
                                        />
                                        <Text
                                            fontSize="10px"
                                            m="2px 0 0 0"
                                            textAlign="center"
                                            color={colors.white}
                                        >
                                            (Showing QR)
                                        </Text>
                                    </Flex>
                                    <Flex flexDirection="column" justifyContent="center" alignItems="center">
                                        <IconImg
                                            src="paperAndPencil.png"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                navigator.clipboard.writeText(`https://cheesy-bean.com/#/`)
                                                makePopupNotif(
                                                    setPopupNotifColor,
                                                    setPopupNotifText,
                                                    colors.offerGreen,
                                                    "copied CheesyBean link!",
                                                    3000,
                                                    "popup-notif-dashboard"
                                                )
                                            }}
                                            height="46px"
                                            width="46px"
                                            border={buttonDecoration.mediumBorder}
                                            p="4px"
                                            bg={colors.offerGreen}
                                            boxShadow={buttonDecoration.veryLightBoxshadow}
                                            borderRadius="6px"
                                        />
                                        <Text
                                            fontSize="10px"
                                            m="2px 0 0 0"
                                            textAlign="center"
                                            color={colors.white}
                                        >
                                            (Copying url)
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Text
                                    fontSize="13px"
                                    textAlign="center"
                                    color="white"
                                    fontWeight="600"
                                    m="10px 0 0 0"
                                >
                                    This is a link to CheesyBean!
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                </ItemExpand>
            )}
            <URLCode />
            <CheesyURLCode />
            <PopupNotification bg={popupNotifColor} text={popupNotifText} id={"popup-notif-dashboard"} />
            {textSubscribeNotif && !firstLogin && <TextSubscribeNotif />}
        </Flex>
    )
}

function URLCode() {
    const { authenticatedUser, firstLogin } = useAuth()

    useLayoutEffect(() => {
        if (authenticatedUser.name) {
            const profileURL = `https://cheesy-bean.com/#/profile/${authenticatedUser.id}`
            generateQR(profileURL, "qrcode")
        }
    }, [authenticatedUser])

    return (
        <StyledConfirmWindow
            className="help-window"
            id="help-window"
            onClick={(e) => {
                if (e.target.classList.contains("help-window")) {
                    document.getElementById("help-window").style.display = "none"
                }
            }}
        >
            <QrCode id="qrcode-container">
                <Text m="0" padding="10px" textAlign="center" fontWeight="600">
                    Add a friend in person!
                </Text>
                <Img id="qrcode" className="qrcode"></Img>
            </QrCode>
        </StyledConfirmWindow>
    )
}

const generateQR = async (text, id) => {
    try {
        const data = await QRCode.toDataURL(text, { errorCorrectionLevel: "H", quality: 1, margin: 0 })
        document.getElementById(id).src = data
        return
    } catch (err) {
        console.error(err)
    }
}

function CheesyURLCode() {
    useLayoutEffect(() => {
        const profileURL = `https://cheesy-bean.com/#/`
        generateQR(profileURL, "qrcode-cheesy")
    }, [])

    return (
        <StyledConfirmWindow
            className="help-window-cheesy"
            id="help-window-cheesy"
            onClick={(e) => {
                if (e.target.classList.contains("help-window-cheesy")) {
                    document.getElementById("help-window-cheesy").style.display = "none"
                }
            }}
        >
            <QrCode id="qrcode-container">
                <Text m="0" padding="10px" textAlign="center" fontWeight="600" fontSize="14px">
                    Share CheesyBean in person!
                </Text>
                <Img id="qrcode-cheesy" className="qrcode-cheesy"></Img>
            </QrCode>
        </StyledConfirmWindow>
    )
}

function makePopupNotif(setPopupNotifColor, setPopupNotifText, color, text, time, id) {
    setPopupNotifColor(color)
    setPopupNotifText(text)
    document.getElementById(id).style.display = "flex"
    setTimeout(() => {
        const notif = document.getElementById(id)
        notif.style.display = "none"
    }, time)
}

const StyledConfirmWindow = styled(Flex)`
    position: fixed;
    z-index: 3;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    backdrop-filter: blur(4px);
    display: none;
`

const StyledImg = styled(Img)`
    &:hover {
        cursor: pointer;
    }
`

const NetSlider = styled(Flex)`
    position: relative;
    margin-top: 10px;
    width: 280px;
    padding: 0.25rem;
    height: 34px;
    align-items: center;
    justify-content: space-around;

    &:hover {
        cursor: pointer;
    }
`

const ItemExpand = styled(Flex)`
    border-radius: 8px;
    box-shadow: ${({ bs }) => (bs ? bs : buttonDecoration.lightBoxshadow)};
    border: ${({ border }) => (border ? border : buttonDecoration.lightBorder)};
    ${buttonDecoration.lightBorder};
    padding: ${({ p }) => (p ? p : "8px 10px")};
    margin: ${({ m }) => (m ? m : "8px 0")};
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease;
    width: 100%;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}
    height: ${({ open, openHeight, closeHeight }) => (open ? openHeight : closeHeight)};
    overflow: hidden;
    ${({ hover }) =>
        hover
            ? `&:hover {
        cursor: pointer;
    }`
            : ""}
`

const IconImg = styled(Img)`
    height: ${({ height }) => (height ? height : "30px")};
    width: ${({ width }) => (width ? width : "30px")};
    ${({ disabled }) => (disabled ? `opacity: 0.5; pointer-events: none;` : "")};

    &:hover {
        cursor: pointer;
    }
`

const ChartSliderButton = styled(Box)`
    position: absolute;
    background-color: ${colors.white};
    border-radius: ${buttonDecoration.lightBorderRadius};
    width: 140px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    left: ${({ tab }) => (tab === "friends" ? "6px" : "140px")};
    height: 30px;
    transition: left 0.3s ease;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${buttonDecoration.lightBorder};
`

const ChartSliderWord = styled(Text)`
    font-size: 18px;
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    z-index: 2;
`

const QrCode = styled(Box)`
    border-radius: 8px;
    position: absolute;
    margin: auto;
    padding: 0 20px 20px 20px;
    background-color: white;
    box-shadow: ${buttonDecoration.mediumBoxshadow};
    border: ${buttonDecoration.mediumBorder};
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
`

const CheesyBeanLogo = styled(Flex)`
    border-radius: 25px;
    background-color: ${colors.cheesyBeanYellow};
    padding: 10px;
    alignitems: center;
    margin-top: 10px;
    margin-bottom: 25px;
    box-shadow: ${buttonDecoration.lightBoxshadow};
    border: ${buttonDecoration.mediumBorder};

    &:hover {
        cursor: pointer;
    }
`

export default Dashboard
