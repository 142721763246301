import react from "react"
import Countdown, { zeroPad } from "react-countdown"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import buttonDecoration from "../buttonDecoration"
import { useBean } from "../context/BeanContext"

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
        <Flex flexDirection="column">
            <Text fontWeight="600" m="0" fontSize="12px" color={colors.medGray}>
                Bean Time!
            </Text>
            <Text m="0" fontWeight="600" fontSize="12px" textAlign="center" color={colors.gray}>
                {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </Text>
        </Flex>
    )
}

function CountDown() {
    const { nextBeanDate } = useBean()

    return nextBeanDate ? (
        <StyledCountDown>
            <Countdown date={new Date(nextBeanDate)} renderer={renderer} />
        </StyledCountDown>
    ) : (
        <></>
    )
}

const StyledCountDown = styled(Flex)`
    top: 10px;
    left: 0px;
    position: absolute;
`
// box-shadow: ${buttonDecoration.veryLightBoxshadow};
// border: ${buttonDecoration.lightBorder};
// border-radius: 6px;
// padding: 4px;

export default CountDown
