const buttonDecoration = {
    veryVeryLightBoxshadow: "1px 1px 0 0 #000",
    veryLightBoxshadow: "2px 2px 0 0 #000",
    lightBoxshadow: "3px 3px 0 0 #000",
    mediumBoxshadow: "4px 4px 0 0 #000",
    heavyBoxshadow: "5px 5px 0 0 #000",
    lightBorder: "1px solid #000",
    mediumBorder: "2px solid #000",
    heavyBorder: "3px solid #000",
    lightBorderRadius: "6px",
    mediumBorderRadius: "8px",
    heavyBorderRadius: "10px",
}

export default buttonDecoration
